import { createSelector } from 'reselect';

import {
  Deployment,
  ExecutionPhase,
  Step,
  StepGroup,
  StepGroupType,
  Variable,
  StepCommand,
  ChildPipelineStep,
} from 'src/components/pipelines/models';
import { getCurrentUserAsPipelinesUser } from 'src/components/pipelines/redux/selectors/users';
import { LoadingStatus } from 'src/constants/loading-status';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import {
  getPipelines as getPipelinesState,
  getRepository as getRepositoryState,
} from 'src/selectors/state-slicing-selectors';
import store from 'src/utils/store';

import {
  FREE_REPORTS_LIMIT,
  METRICS_OOM_FAILURE_TRESHOLD_IN_MB,
  METRICS_USAGE_ERROR_LIMIT,
  METRICS_WARNING_LIMIT,
  PIPELINES_ONBOARDED_KEY,
} from '../../constants';

export const getWebsocketSubscriptions = createSelector(
  getRepositoryState,
  ({ deployments }) => deployments.websocket
);

export const getAccount = createSelector(
  getPipelinesState,
  pipelines => pipelines.account
);

export const getAllowance = createSelector(
  getPipelinesState,
  pipelines => pipelines.allowance
);

export const getCaches = createSelector(
  getPipelinesState,
  pipelines => pipelines.caches.list
);

export const getSchedules = createSelector(
  getPipelinesState,
  pipelines => pipelines.schedules.list
);

export const getScheduleStatus = createSelector(
  [getPipelinesState],
  pipelines => pipelines.schedules.createdStatus
);

export const getCapabilities = createSelector(
  getPipelinesState,
  pipelines => pipelines.capabilities
);

export const getJsdChecks = createSelector(
  getPipelinesState,
  pipelines => pipelines.jsdChecks
);

export const getCheckSuite = createSelector(
  getJsdChecks,
  jsdChecks => (checkSuiteId: string) =>
    jsdChecks.checks.check_suites[checkSuiteId]
);

export const getRepository = createSelector(
  getPipelinesState,
  pipelines => pipelines.repository
);

export const getLog = createSelector(
  getPipelinesState,
  pipelines => pipelines.log
);

export const getSearchLog = createSelector(
  getPipelinesState,
  pipelines => pipelines.searchLog.searchLog
);

export const getIsFetchingSearchLog = createSelector(
  getPipelinesState,
  pipelines => pipelines.searchLog.fetchedStatus === LoadingStatus.Fetching
);

export const getIsSearchingLog = createSelector(
  getPipelinesState,
  pipelines => pipelines.searchLog.searchedStatus === LoadingStatus.Fetching
);

export const getIsDeletingLog = createSelector(
  getLog,
  pipelines => pipelines.fetchedStatus.DELETE_LOG === LoadingStatus.Fetching
);

export const getPipelines = createSelector(getPipelinesState, pipelines => {
  return pipelines.pipelines.list;
});

export const getPipelinesSize = createSelector(getPipelinesState, pipelines => {
  return pipelines.pipelines.size;
});

export const getPipelinesPage = createSelector(getPipelinesState, pipelines => {
  return pipelines.pipelines.page;
});

export const getCurrentPipeline = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.currentPipeline.pipeline;
  }
);

export const getIsLoadingCurrentPipeline = createSelector(
  getPipelinesState,
  state => {
    const { fetchedStatus } = state.currentPipeline;
    return (
      fetchedStatus === LoadingStatus.Before ||
      fetchedStatus === LoadingStatus.Fetching
    );
  }
);

export const getDynamicPipelinesConfiguration = createSelector(
  getPipelinesState,
  pipelines => {
    const configurationSources =
      pipelines.currentPipeline.pipeline.configuration_sources;
    const lastConfigurationSource =
      configurationSources[configurationSources.length - 1];

    if (
      lastConfigurationSource.source &&
      ['DYNAMIC_REPOSITORY', 'DYNAMIC_WORKSPACE'].includes(
        lastConfigurationSource.source
      )
    ) {
      return lastConfigurationSource;
    }

    return { uri: undefined, source: undefined };
  }
);

export const getPipelineCreators = createSelector(
  getPipelinesState,
  getCurrentUserAsPipelinesUser,
  (pipelines, currentUser) => {
    if (currentUser != null && pipelines.pipelines.creators.length > 0) {
      return [
        currentUser,
        ...pipelines.pipelines.creators.filter(
          c => c.uuid !== currentUser.uuid
        ),
      ];
    } else {
      return pipelines.pipelines.creators;
    }
  }
);

export const getCurrentStepUuid = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.currentStepUuid;
  }
);

export const getFetchedStepBranchRestrictionsMap = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.fetchedBranchRestrictions;
  }
);

export const getIsFetchingStepBranchRestrictionsMap = createSelector(
  getPipelinesState,
  pipelines => {
    return Object.keys(pipelines.steps.fetchedBranchRestrictions).reduce(
      (reducer, key) => {
        reducer[key] =
          pipelines.steps.fetchedBranchRestrictions[key] ===
          LoadingStatus.Fetching;
        return reducer;
      },
      {} as any
    );
  }
);

export const getTestReport = createSelector(getPipelinesState, pipelines => {
  return pipelines.testReport;
});

export const getCurrentStep = createSelector(getPipelinesState, pipelines => {
  if (!pipelines.steps.map || !(pipelines.steps.map instanceof Map)) {
    return new Step();
  }
  return pipelines.steps.map.get(pipelines.steps.currentStepUuid) || new Step();
});

export const getStepCommands = createSelector(getPipelinesState, pipelines => {
  return pipelines.steps.commands;
});

export const getCommandsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.commands[pipelines.steps.currentStepUuid] || [];
  }
);

export const getExpandedCommands = createSelector(
  getPipelinesState,
  pipelines => pipelines.steps.expandedCommands || {}
);

export const getExpandedCommandsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines =>
    pipelines.steps.expandedCommands[pipelines.steps.currentStepUuid] || []
);

export const getDownloadedCommandsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines =>
    pipelines.steps.downloadedCommands[pipelines.steps.currentStepUuid] || []
);

export const getErroredCommandsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines =>
    pipelines.steps.erroredCommands[pipelines.steps.currentStepUuid] || []
);

export const getStreamingCommandsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines => {
    return (
      pipelines.steps.streamingCommands[pipelines.steps.currentStepUuid] || []
    );
  }
);

export const getStepGroups = createSelector(getPipelinesState, pipelines => {
  // console.log('get Step Groups')
  // console.log(pipelines)
  // problem is this goes from [StepGroup] with steps: Array(2)
  // to [StepGroup, StepGroup] with steps: Array[1]
  return pipelines.steps.groups;
});

export const getStepsMap = createSelector(getPipelinesState, pipelines => {
  // @ts-ignore use es2019 lib
  return Object.fromEntries(pipelines.steps.map) as Record<
    string,
    Step | ChildPipelineStep
  >;
});

export const getIsLoadingSteps = createSelector(getPipelinesState, state => {
  const { fetchedStatus } = state.steps;
  return (
    fetchedStatus === LoadingStatus.Before ||
    fetchedStatus === LoadingStatus.Fetching
  );
});

export const getStepsEnvironments = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.environments;
  }
);

export const getRunningManualStep = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.runningManualStep as false | string;
  }
);

export const getStageActionInProgress = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.stageActionInProgress as number | undefined;
  }
);

export const getIsFetchingStepRerun = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.steps.fetchedStepRerunStatus === LoadingStatus.Fetching;
  }
);

export const getRerunnableStepWithEnvironment = createSelector(
  getStepsMap,
  stepsMap =>
    Object.values(stepsMap).filter(
      (step: Step) => step.isRerunnable && step.hasEnvironment
    )[0] as Step
);

export const getHasFailedRedeploy = createSelector(getStepsMap, stepsMap =>
  Object.values(stepsMap).some(
    (step: Step) => step.isRedeployed && step.isRerunnable
  )
);

export const getHasAdvancedFeatures = createSelector(
  getStepsMap,
  getCurrentPipeline,
  getStepGroups,
  (stepsMap, pipeline, groups) => {
    const steps = Object.values(stepsMap) as (Step | ChildPipelineStep)[];
    const hasRerun = pipeline.run_number > 1;
    const has2xStep = steps.some(
      step => !step.isChildStep && step.stepSize > 1
    );
    const hasParallelGroup =
      groups &&
      groups.some((group: StepGroup) => group.type === StepGroupType.PARALLEL);
    return (
      steps.length > 0 &&
      (has2xStep || hasParallelGroup || hasRerun) &&
      pipeline.isComplete
    );
  }
);

export const getArtifactsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.artifacts.map[pipelines.steps.currentStepUuid] || [];
  }
);

export const getCommitPullRequests = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.commitPullRequests;
  }
);

export const getReports = createSelector(
  getPipelinesState,
  getCapabilities,
  (pipelines, capabilities) => {
    return (pipelines.reports.list || []).map((report, index) => ({
      ...report?.toJS?.(),
      is_locked:
        !capabilities.isPaidOrPremium && index + 1 > FREE_REPORTS_LIMIT,
    })) as any[];
  }
);

export const getAnnotations = createSelector(getPipelinesState, pipelines => {
  return (pipelines.reports.annotations || []) as any[];
});

export const getIsFetchingReports = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.reports.fetchedStatus === LoadingStatus.Fetching;
  }
);

export const getIsFetchingAnnotations = createSelector(
  getPipelinesState,
  pipelines => {
    return pipelines.reports.fetchedAnnotationStatus === LoadingStatus.Fetching;
  }
);

export const getIsFetchingArtifactsForCurrentStep = createSelector(
  getPipelinesState,
  pipelines => {
    return (
      pipelines.artifacts.fetchedStatus[pipelines.steps.currentStepUuid] ===
      LoadingStatus.Fetching
    );
  }
);

export const getStepLogForCurrentStep = createSelector(
  getCurrentStepUuid,
  getLog,
  (stepUuid, log) => log.stepLogs[stepUuid] || new Map()
);

export const getLogCommandsForCurrentStep = createSelector(
  getCurrentStepUuid,
  getLog,
  (stepUuid, log) => log.commands[stepUuid] || []
);

// need to be removed in CICD-2832
export const getLastLogForCurrentStep = createSelector(
  getLogCommandsForCurrentStep,
  logCommands => logCommands[logCommands.length - 1]
);

export const getFullLogForCurrentStep = createSelector(
  getCurrentStepUuid,
  getLog,
  (stepUuid, log) => log.fullLog[stepUuid]
);

export const getFullLogErrorForCurrentStep = createSelector(
  getCurrentStepUuid,
  getLog,
  (stepUuid, log) => log.fullLogError[stepUuid]
);

export const getInfiniteLog = createSelector(
  getLogCommandsForCurrentStep,
  getCommandsForCurrentStep,
  getExpandedCommandsForCurrentStep,
  getDownloadedCommandsForCurrentStep,
  (logCommands, commands, expandedCommands, downloadedCommands) => {
    return commands
      .map((command, index) => {
        const logForCommand = logCommands[index] || [];
        const expandedLog =
          logForCommand[logForCommand.length - 1] !== ''
            ? logForCommand.concat('')
            : logForCommand;
        const isExpanded = expandedCommands[index];
        const isDownloaded = downloadedCommands[index];
        return isExpanded && isDownloaded
          ? [command, ...expandedLog]
          : [command];
      })
      .flat();
  }
);

// this and the function above `getInfiniteLog` needs to be refactored to be more performant
// https://softwareteams.atlassian.net/browse/CICD-5832
export const getExpandedInfiniteLog = createSelector(
  getLogCommandsForCurrentStep,
  getCommandsForCurrentStep,
  getDownloadedCommandsForCurrentStep,
  (logCommands, commands, downloadedCommands) =>
    commands
      .map((command: StepCommand, index: number) => {
        const logForCommand = logCommands[index] || [];
        const expandedLog =
          logForCommand[logForCommand.length - 1] !== ''
            ? logForCommand.concat('')
            : logForCommand;
        const isDownloaded = downloadedCommands[index];
        return isDownloaded ? [command, ...expandedLog] : [command];
      })
      .flat()
);

export const getIsCommandDownloaded = createSelector(
  getDownloadedCommandsForCurrentStep,
  downloadedCommands => (command: StepCommand) =>
    !!downloadedCommands[command.index]
);

export const getIsCommandExpanded = createSelector(
  getExpandedCommandsForCurrentStep,
  expandedCommands => (command: StepCommand) =>
    !!expandedCommands[command.index]
);

export const getIsCommandErrored = createSelector(
  getErroredCommandsForCurrentStep,
  erroredCommands => (command: StepCommand) => !!erroredCommands[command.index]
);

export const getFailedCommandForStep = createSelector(
  getCurrentStep,
  getCommandsForCurrentStep,
  (step, commands) => {
    if (!step.isFailed) {
      return undefined;
    }

    const filteredCommands = commands.filter(
      (cmd: StepCommand) =>
        cmd.execution_phase === ExecutionPhase.MAIN &&
        cmd.log_range.byte_count > 0
    );

    return filteredCommands[filteredCommands.length - 1];
  }
);

export const getIsCommandFailed = createSelector(
  getFailedCommandForStep,
  failedCommand => (command: any) => failedCommand === command
);

export const getStreamingCommands = createSelector(
  getPipelinesState,
  pipelines => {
    const streamdingCmds: { [key: string]: number | undefined } = {};

    const { steps } = pipelines;

    for (const stepUuid in steps.streamingCommands) {
      if (
        Object.prototype.hasOwnProperty.call(steps.streamingCommands, stepUuid)
      ) {
        const activeCmdArr = steps.streamingCommands[stepUuid];
        streamdingCmds[stepUuid] =
          activeCmdArr && activeCmdArr.length
            ? activeCmdArr.lastIndexOf(true)
            : undefined;
      }
    }

    return streamdingCmds;
  }
);

export const getStatusOfFailureUpdate = createSelector(
  getPipelinesState,
  pipelines => {
    const { steps } = pipelines;
    return steps.failureReasonUpdated;
  }
);

export const getIsCommandStreaming = createSelector(
  getStreamingCommandsForCurrentStep,
  streamingCommands => (command: StepCommand) =>
    !!streamingCommands[command.index]
);

export const getIsAnyCommandStreaming = createSelector(
  getStreamingCommandsForCurrentStep,
  streamingCommands => streamingCommands.some(x => x !== undefined)
);

export const getIsFetchingLog = createSelector(getLog, log =>
  Object.keys(log.fetchedStatus).reduce((reducer, key) => {
    reducer[key] = log.fetchedStatus[key] === LoadingStatus.Fetching;
    return reducer;
  }, {} as any)
);

export const getServiceLog = createSelector(
  getLog,
  log => log.serviceLog || {}
);

export const getServiceLogErrorForCurrentStep = createSelector(
  getCurrentStepUuid,
  getLog,
  (stepUuid, log) => log.serviceLogError[stepUuid]
);

export const getValidator = createSelector(
  getPipelinesState,
  pipelines => pipelines.validator
);

export const getBuildConfiguration = createSelector(
  getPipelinesState,
  pipelines => pipelines.buildConfiguration
);

export const getVariables = createSelector(getPipelinesState, pipelines => {
  return Object.values(
    Object.fromEntries(pipelines.variables.map)
  ) as Variable[];
});

export const getVariablesFetchedStatus = createSelector(
  getPipelinesState,
  pipelines => pipelines.variables.fetchedStatus
);

export const getIsFetchingVariables = createSelector(
  getPipelinesState,
  pipelines => pipelines.variables.fetchedStatus === LoadingStatus.Fetching
);

export const getVariablesPage = createSelector(
  getPipelinesState,
  pipelines => pipelines.variables.page
);

export const getVariablesHasNextPage = createSelector(
  getPipelinesState,
  pipelines => Boolean(pipelines.variables.loadMore)
);

export const getEnvironmentVariables = createSelector(
  getPipelinesState,
  pipelines =>
    Object.keys(pipelines.environmentVariables.map).reduce(
      (reducer, environmentUuid: string) => {
        reducer[environmentUuid] = Object.values(
          Object.fromEntries(
            pipelines.environmentVariables.map[environmentUuid].map
          )
        ) as Variable[];
        return reducer;
      },
      {} as Record<string, Variable[]>
    )
);

export const getEnvironmentVariablesFetchState = createSelector(
  getPipelinesState,
  pipelines =>
    Object.keys(pipelines.environmentVariables.map).reduce(
      (reducer, environmentUuid: string) => {
        reducer[environmentUuid] =
          pipelines.environmentVariables.map[environmentUuid].fetchedStatus;
        return reducer;
      },
      {} as Record<string, LoadingStatus>
    )
);

export const getEnvironmentVariablesPage = createSelector(
  getPipelinesState,
  pipelines =>
    Object.keys(pipelines.environmentVariables.map).reduce(
      (reducer, environmentUuid: string) => {
        reducer[environmentUuid] =
          pipelines.environmentVariables.map[environmentUuid].page;
        return reducer;
      },
      {} as Record<string, number>
    )
);

export const getEnvironmentVariablesHasNextPage = createSelector(
  getPipelinesState,
  pipelines =>
    Object.keys(pipelines.environmentVariables.map).reduce(
      (reducer, environmentUuid: string) => {
        reducer[environmentUuid] =
          pipelines.environmentVariables.map[environmentUuid].loadMore;
        return reducer;
      },
      {} as Record<string, boolean>
    )
);

export const getIsLoadingPipelines = createSelector(
  getPipelinesState,
  state => {
    const { fetchedStatus } = state.pipelines;
    return (
      fetchedStatus === LoadingStatus.Before ||
      fetchedStatus === LoadingStatus.Fetching
    );
  }
);

export const getMemoryLimits = createSelector(getCurrentStep, step => {
  if (step.isChildStep) return {};

  return {
    build: step.memoryLimitInMegabytes,
    ...step.customServices.reduce(
      (reducer, service) => ({
        [service.name]: service.memoryLimitInMegabytes,
        ...reducer,
      }),
      {}
    ),
  };
});

export const getCpuLimits = createSelector(getCurrentStep, step => {
  if (step.isChildStep) return {};

  return {
    build: step.cpuLimitInMilicores,
    ...step.customServices.reduce(
      (reducer, service) => ({
        [service.name]: service.cpuLimitInMilicores,
        ...reducer,
      }),
      {}
    ),
  };
});

export const getMetrics = createSelector(getPipelinesState, pipeline => {
  return pipeline.metrics;
});

export const getIsFetchingMetrics = createSelector(
  getMetrics,
  metrics => metrics.fetchedStatus === LoadingStatus.Fetching
);

export const getStepMetrics = createSelector(
  getCurrentStep,
  getMetrics,
  (step, metrics) =>
    metrics.metrics[step.uuid] || {
      MEMORY: {},
      CPU: {},
      MEMORY_PERCENTAGE: {},
    }
);

export const getMemoryMetrics = createSelector(
  getCurrentStep,
  getStepMetrics,
  (step, stepMetrics) => {
    if (step.isChildStep) return [];

    return Object.values(stepMetrics.MEMORY).map(metric => {
      return {
        timestamp: metric.sample_time,
        values: Object.keys(metric.value).reduce(
          (reducer, serviceUuid) => {
            const service = step.customServices.find(
              s => s?.uuid === serviceUuid
            );
            if (service?.name) {
              reducer[service.name] =
                metric.value[service.uuid] +
                  METRICS_OOM_FAILURE_TRESHOLD_IN_MB >
                service.memoryLimitInMegabytes
                  ? service.memoryLimitInMegabytes
                  : metric.value[service.uuid];
            }
            return reducer;
          },
          {
            build:
              metric.value.build + METRICS_OOM_FAILURE_TRESHOLD_IN_MB >
              step.memoryLimitInMegabytes
                ? step.memoryLimitInMegabytes
                : metric.value.build,
          } as any
        ),
      };
    });
  }
);

export const getCpuMetrics = createSelector(
  getCurrentStep,
  getStepMetrics,
  (step, stepMetrics) => {
    if (step.isChildStep) return [];
    return Object.values(stepMetrics.CPU).map(metric => ({
      timestamp: metric.sample_time,
      values: Object.keys(metric.value).reduce(
        (reducer, serviceUuid) => {
          const service = step.customServices.find(
            s => s?.uuid === serviceUuid
          );
          if (service?.name) {
            reducer[service.name] =
              metric.value[service.uuid] >= service.cpuLimitInMilicores
                ? service.cpuLimitInMilicores
                : metric.value[service.uuid];
          }
          return reducer;
        },
        {
          build:
            metric.value.build >= step.cpuLimitInMilicores
              ? step.cpuLimitInMilicores
              : metric.value.build,
        } as any
      ),
    }));
  }
);

export const getMaxMemoryPercentage = createSelector(
  getCurrentStep,
  getStepMetrics,
  (step, stepMetrics) => {
    if (step.isChildStep) return {};

    const percentages = Object.values(stepMetrics.MEMORY_PERCENTAGE).map(
      metric => {
        return {
          values: Object.keys(metric.value).reduce(
            (reducer, serviceUuid) => {
              const service = step.customServices.find(
                s => s?.uuid === serviceUuid
              );
              if (service?.name) {
                reducer[service.name] = metric.value[service.uuid];
              }
              return reducer;
            },
            { build: metric.value.build } as any
          ),
        };
      }
    );

    const maxVals: any = {};
    percentages.forEach((element: any) => {
      Object.keys(element.values).forEach(service => {
        maxVals[service] =
          element.values[service] === undefined ||
          element.values[service] < maxVals[service]
            ? maxVals[service]
            : element.values[service];
      });
    });

    return maxVals;
  }
);

export const getContainerStates = createSelector(
  getMaxMemoryPercentage,
  maxMemoryPercentage => {
    return Object.keys(maxMemoryPercentage).reduce((reducer, container) => {
      if (maxMemoryPercentage[container] >= METRICS_USAGE_ERROR_LIMIT) {
        reducer[container] = 'error';
      } else if (maxMemoryPercentage[container] >= METRICS_WARNING_LIMIT) {
        reducer[container] = 'warning';
      }
      return reducer;
    }, {} as any);
  }
);

export const getExpandedTestCaseMap = createSelector(
  getTestReport,
  testReport => testReport.expandedTestCaseMap
);

export const getIsFetchingFailedTestCases = createSelector(
  getTestReport,
  testReport => testReport.fetchingFailedTestCases
);

export const getIsFetchingTestReasonMap = createSelector(
  getTestReport,
  testReport => testReport.fetchingTestReasonMap
);

export const getFailedTestCases = createSelector(
  getCurrentStepUuid,
  getTestReport,
  (stepUuid, testReport) => testReport.failedTestCases[stepUuid] || []
);

export const getTestCaseReasonMap = createSelector(
  getTestReport,
  testReport => testReport.testCaseReasonMap
);

export const getTestCaseErrorMap = createSelector(
  getTestReport,
  testReport => testReport.testCaseErrorMap
);

export const getIsFetchingCaches = createSelector(
  getPipelinesState,
  pipelines => pipelines.caches.fetchedStatus === LoadingStatus.Fetching
);

export const getIsFetchingSchedules = createSelector(
  getPipelinesState,
  pipelines => pipelines.schedules.fetchedStatus === LoadingStatus.Fetching
);

export const getStepDeploymentPreview = createSelector(
  getPipelinesState,
  pipelines => {
    const { environment, deployment } = pipelines.stepPreview;

    const previousDeployment = (environment.latest_successful_deployment ||
      new Deployment()) as Deployment;
    const previousCommit = previousDeployment?.deployable?.commit;
    const currentCommit = deployment?.deployable?.commit;

    const isRollback =
      new Date(previousCommit?.date || '').getTime() >
      new Date(currentCommit?.date || '').getTime();
    const createdOn = isRollback
      ? previousDeployment?.deployable?.created_on || ''
      : deployment?.deployable?.created_on || '';

    return {
      deployment,
      environment,
      previousDeployment,
      revisionFrom:
        !previousCommit?.hash && currentCommit?.parentHash
          ? currentCommit.parentHash
          : previousCommit?.hash || '',
      revisionTo: currentCommit?.hash || '',
      createdOn,
      isRollback,
    };
  }
);

export const getStepRedeployment = createSelector(
  getPipelinesState,
  pipelines => {
    const {
      deployment,
      environment,
      lastSuccessfulDeployment: previousDeployment,
    } = pipelines.stepRedeployment;

    const previousCommit = previousDeployment.deployable.commit;
    const currentCommit = deployment.deployable.commit;

    const isRollback =
      new Date(previousCommit?.date || '').getTime() >
      new Date(currentCommit?.date || '').getTime();
    const createdOn = isRollback
      ? previousDeployment.deployable.created_on || ''
      : deployment.deployable.created_on || '';

    return {
      deployment,
      environment,
      previousDeployment,
      revisionFrom:
        !previousCommit?.hash && currentCommit?.parentHash
          ? currentCommit.parentHash
          : previousCommit?.hash || '',
      revisionTo: currentCommit?.hash || '',
      createdOn,
      isRollback,
    };
  }
);

export const getHasOnboardedPipelines = createSelector(
  getCurrentRepository,
  getPipelines,
  (repository, pipelines) => {
    const key = `${PIPELINES_ONBOARDED_KEY}-${repository?.uuid}`;
    if (store.get(key)) {
      return true;
    } else if (pipelines.length > 0) {
      store.set(`${PIPELINES_ONBOARDED_KEY}-${repository?.uuid}`, true);
      return true;
    }
    return false;
  }
);
