import FeatureGates from '@atlaskit/feature-gate-js-client';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import FeatureFlagClient, {
  AnalyticsClientInterface,
  FeatureFlagUser,
  GetValueOptions,
  SupportedFlagTypes,
} from '@atlassiansox/feature-flag-web-client';

import { FeatureConfig } from './types';

let globalFeatureFlagClient: FeatureFlagClient | null = null;

export const getFlagValue = (
  flagKey: string,
  defaultValue: SupportedFlagTypes,
  options?: GetValueOptions<SupportedFlagTypes>
): SupportedFlagTypes => {
  return (
    globalFeatureFlagClient?.getFlagValue(flagKey, defaultValue, options) ??
    defaultValue
  );
};

const statsigFeatureGate: typeof FeatureGates.checkGate = gateName => {
  return FeatureGates.checkGate(gateName);
};

export const getGlobalFeatureFlagClient = ({
  apiKey,
  analyticsWebClient,
  featureFlagUser,
  options,
}: FeatureConfig & {
  analyticsWebClient: AnalyticsClientInterface;
  featureFlagUser: FeatureFlagUser;
}): FeatureFlagClient => {
  if (!globalFeatureFlagClient) {
    globalFeatureFlagClient = new FeatureFlagClient(
      apiKey,
      analyticsWebClient,
      featureFlagUser,
      options
    );

    try {
      /**
       * Platform Feature flags (PFF): https://hello.atlassian.net/wiki/spaces/AF/pages/2634731342/Platform+Feature+Flags
       *
       * This creates a PFF resolver that allows calls to `fg` inside of Platform components to use
       * statsig feature gates as well as your feature flag client
       * and LD project, rather than installing a feature flag client of their own, or passing those flags in via props.
       */
      globalFeatureFlagClient.ready().then(() => {
        setBooleanFeatureFlagResolver((flagKey: string) => {
          if (statsigFeatureGate(flagKey)) {
            return true;
          }

          return Boolean(globalFeatureFlagClient!.getFlagValue(flagKey, false));
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not initialize Platform Feature Flags', error);
    }
  }

  return globalFeatureFlagClient;
};
