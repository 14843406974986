import { useCallback, useContext, useEffect, useRef } from 'react';
import { bindAll } from 'bind-event-listener';
import { LevelContext, TopLevelContext } from '../components/layering-context';
var ESCAPE = 'Escape';
/**
 * Calls back when the escape key is pressed.
 * To be used exclusively for closing layered components.
 * Use the `isDisabled` argument to ignore closing events.
 *
 * ```js
 * useCloseOnEscapePress({
 *   onClose: () => {},
 *   isDisabled: false,
 * });
 * ```
 */
export function useCloseOnEscapePress(_ref) {
  var onClose = _ref.onClose,
    isDisabled = _ref.isDisabled;
  var escapePressed = useRef(false);
  var _UNSAFE_useLayering = UNSAFE_useLayering(),
    isLayerDisabled = _UNSAFE_useLayering.isLayerDisabled;
  var onKeyDown = useCallback(function (e) {
    var isDisabledLayer = isLayerDisabled();
    if (isDisabled || escapePressed.current || e.key !== ESCAPE || isDisabledLayer) {
      // We're either already handling the key down event or it's not escape or disabled.
      // Bail early!
      return;
    }
    escapePressed.current = true;
    onClose(e);
  }, [onClose, isDisabled, isLayerDisabled]);
  var onKeyUp = useCallback(function () {
    escapePressed.current = false;
  }, []);
  useEffect(function () {
    return bindAll(document, [{
      type: 'keydown',
      listener: onKeyDown
    }, {
      type: 'keyup',
      listener: onKeyUp
    }], {
      capture: false
    });
  }, [onKeyDown, onKeyUp]);
}

/**
 *
 * @experimental Still under development. Do not use.
 *
 * Layering hook to get layering info like the current level, the top level of
 * the given component
 *
 */
export function UNSAFE_useLayering() {
  var currentLevel = useContext(LevelContext);
  var _useContext = useContext(TopLevelContext),
    topLevelRef = _useContext.topLevelRef;
  var isLayerDisabled = useCallback(function () {
    return !!topLevelRef.current && currentLevel !== topLevelRef.current;
  }, [currentLevel, topLevelRef]);
  return {
    currentLevel: currentLevel,
    topLevelRef: topLevelRef,
    isLayerDisabled: isLayerDisabled
  };
}