import { Pipeline } from 'src/components/pipelines/models';

export const getPageNumber = (page: string): number => Number(page) || 1;

export const shouldRefreshPipelines = (
  updatedPipelineUuid: Pipeline['uuid'],
  currentPipelines: Pipeline[],
  createdPipelineUuids: Pipeline['uuid'][]
): boolean => {
  const isPipelineUpdateForNewlyCreatedPipeline =
    createdPipelineUuids.includes(updatedPipelineUuid);
  const listContainsUpdatedPipeline = currentPipelines
    .map(p => p.uuid)
    .includes(updatedPipelineUuid);

  return listContainsUpdatedPipeline || isPipelineUpdateForNewlyCreatedPipeline;
};

export const isValidURL = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
