import { useContext, useEffect } from 'react';
import { useQuery as useApolloQuery } from '@apollo/react-hooks';
import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';
import getOperationName from '../utils/get-operation-name';
export var useQuery = function useQuery() {
  var ufoContext = useContext(UFOInteractionContext);
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  var apolloResult = useApolloQuery.apply(void 0, args);
  var query = args[0];
  var operationName = getOperationName(query);
  var isLoading = apolloResult === null || apolloResult === void 0 ? void 0 : apolloResult.loading;
  useEffect(function () {
    if (isLoading) {
      return ufoContext === null || ufoContext === void 0 ? void 0 : ufoContext.hold(operationName);
    }
  }, [isLoading, operationName, ufoContext]);
  return apolloResult;
};