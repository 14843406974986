import qs from 'qs';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';

const urls = {
  api: {
    internal: {
      toggleFeature: (owner: string, feature: string) =>
        `/!api/internal/account/${owner}/features/${feature}`,
      contentPreview: () => `/!api/internal/content-preview/`,
    },
    v10: {},
    v20: {
      // This uses the WorkspacePermissionsHandler in core
      workspacePermissionsForUser: (
        workspaceUuid: string,
        currentUserUuid: string
      ) =>
        `/!api/2.0/workspaces/${encodeURIComponent(
          workspaceUuid
        )}/permissions?${qs.stringify({
          fields: 'values.permissions',
          q: `user.uuid="${currentUserUuid}"`,
        })}`,

      // This uses the UserPermissionsWorkspacesHandler in core
      userPermissionsForWorkspace: (workspaceUuid: string) =>
        `/!api/2.0/user/permissions/workspaces?${qs.stringify({
          fields: 'values.permissions',
          q: `workspace.uuid="${workspaceUuid}"`,
        })}`,
    },
  },
  external: {
    atlassianAccountLogin: (
      aaLoginUrl: string,
      canonUrl: string,
      prompt?: 'select_account'
    ) =>
      `${aaLoginUrl}?${qs.stringify({
        application: 'bitbucket',
        continue: canonUrl,
        prompt,
      })}`,
    // TODO: Remove this once every page and navigation implementation is using
    // the navigation API and resource (which will provide logoutUrl instead of
    // having to manually calculate it)
    atlassianAccountLogout: (aaLogoutUrl: string, canonUrl: string) =>
      `${aaLogoutUrl}?${qs.stringify({
        continue: `${canonUrl}${urls.ui.logout()}`,
        prompt: 'none',
      })}`,
    fileRenderingLimitDocs:
      'https://confluence.atlassian.com/display/BITBUCKET/Limits+for+viewing+content+and+diffs',
    socialAuthLink: '/socialauth/login/atlassianid/',
    codeInsightsLearnMore:
      'https://confluence.atlassian.com/display/BITBUCKET/Code+insights',
    resolveMergeConflictDocs:
      'https://support.atlassian.com/bitbucket-cloud/docs/resolve-merge-conflicts/',
    grantRepositoryAccessToUsersAndGroups:
      'https://support.atlassian.com/bitbucket-cloud/docs/grant-repository-access-to-users-and-groups/',
    mergeStrategiesLearnMore:
      'https://confluence.atlassian.com/x/idFbO#Merge-strategies',
    adminHub: (
      adminHubBaseUrl: string,
      orgId: string,
      workspaceUuid: string,
      path: string
    ) => {
      const workspaceAri = `ari:cloud:bitbucket::workspace/${uncurlyUuid(
        workspaceUuid
      )}`;
      const params = {
        product: workspaceAri,
        referrerName: 'Bitbucket',
      };
      return `${adminHubBaseUrl}/o/${orgId}/${path}?${qs.stringify(params)}`;
    },
  },
  ui: {
    adminPlans: '/account/admin/plans/',
    fullCommit: (repositoryFullSlug: string, commitHash: string) =>
      `/${repositoryFullSlug}/commits/${commitHash}`,
    // TODO: Remove this once every page and navigation implementation is using
    // the navigation API and resource (which will provide logoutUrl instead of
    // having to manually calculate it)
    logout: () => '/account/signout/',
    project: (owner: string, key: string) =>
      `/account/user/${owner}/projects/${key}`,
    workspacePlansAndBilling: (slug: string) =>
      `/${slug}/workspace/settings/plans-and-billing/`,
    workspaceUsersOnPlan: (slug: string) =>
      `/${slug}/workspace/settings/plan-users/`,
    workspaceGroups: (slug: string) => `/${slug}/workspace/settings/groups/`,
    repositoryMentions: (owner: string, slug: string, query: string) =>
      `/xhr/mentions/repositories/${owner}/${slug}?term=${query}`,
    pullRequest: (repositoryFullSlug: string, pullRequestId: number) =>
      `/${repositoryFullSlug}/pull-requests/${pullRequestId}`,
    pullRequestTab: (
      owner: string,
      slug: string,
      pullRequestId: string | number,
      tab: 'overview' | 'commits' | 'diff'
    ): string => `/${owner}/${slug}/pull-requests/${pullRequestId}/${tab}`,
  },
};

export default urls;
