import { createResource } from 'react-resource-router';

import {
  TestCase,
  type TestCaseResponse,
} from 'src/components/pipelines/models/TestReport';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';

import { fetchData } from './utils';

export type Params = {
  repositoryFullSlug?: string;
};

export function getTestCasesUrl(params: Params, page: string) {
  return `${envBaseUrl('/!api/internal')}/repositories/${
    params.repositoryFullSlug
  }/test-cases?page=${page}`;
}

export const loadTestCasesResource = createResource({
  type: 'pipelinesTestCases',
  getKey: ({ match, query }) =>
    `${getTestCasesUrl(match.params, query.page || '1')}`,
  maxAge: 60000,
  getData: async ({ match, query }) => {
    const testCasesData = await fetchData(
      getTestCasesUrl(match.params, query.page || '1'),
      'test_cases_resource_exception'
    );

    return {
      page: testCasesData.page,
      pagelen: testCasesData.pagelen,
      size: testCasesData.size,
      values: testCasesData.values.map(
        (testCase: TestCaseResponse) => new TestCase(testCase)
      ),
    };
  },
});
