import Loadable from 'react-loadable';

import { loadThirdPartySecretsProviderResource } from 'src/components/pipelines/components/third-party-secrets/components/secret-provider-endpoints';
import { loadWorkspaceDynamicPipelinesResource } from 'src/components/pipelines/resources/dynamic-pipelines';
import { loadPipelinesWorkspaceRunnersResource } from 'src/components/pipelines/resources/runners';
import { loadPipelinesWorkspaceVariablesResource } from 'src/components/pipelines/resources/workspace-variables';
import { LoadingPage } from 'src/components/react-loadable';
import { customMergeChecksConfigRouteResource } from 'src/components/settings/merge-checks/resources';
import { loadGlobalResource } from 'src/redux/global/resources/load-global';
import { dashboardOverviewViewResource } from 'src/sections/dashboard/dashboard-overview-view-resource';
import { workspaceRepositoriesResource } from 'src/sections/profile/sections/repositories/workspace-repositories-resource';
import { workspaceProjectsResource } from 'src/sections/workspace/sections/projects/workspace-projects-resource';
import { workspacePullRequestsResource } from 'src/sections/workspace/sections/pullrequests/workspace-pull-requests-resource';
import { WorkspaceSettingsAccessTokensLoadable } from 'src/sections/workspace/sections/settings/components/access-tokens';
import { compassResource } from 'src/sections/workspace/sections/settings/components/compass/compass-resource';
import { WorkspaceSettingsPermissionsLoadable } from 'src/sections/workspace/sections/settings/components/permissions';
import { loadWorkspaceSettingsPermissionsResource } from 'src/sections/workspace/sections/settings/components/permissions/resources';
import PipelinesWorkspaceDynamicPipelinesPageLoadable from 'src/sections/workspace/sections/settings/components/pipelines/dynamic-pipelines';
import PipelinesWorkspaceRunnersPageLoadable from 'src/sections/workspace/sections/settings/components/pipelines/runners';
import PipelinesWorkspaceVariablesPageLoadable from 'src/sections/workspace/sections/settings/components/pipelines/variables';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';
import { workspaceSectionResource } from 'src/sections/workspace/workspace-section-resource';

import { ROUTE_NAME, RouteConfig, RouteFeatures } from '../types';

const RootRedirect = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/global/containers/root-redirect'),
});

const DashboardOverview = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/dashboard/sections/overview/containers'),
});

const WorkspaceLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/workspace/layout'),
});

const WorkspaceSettingsLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/workspace/settings-layout'),
});

const WorkspaceCreatePage = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/create/containers/create-workspace-page'
    ),
});

const WorkspacePullRequests = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/workspace/sections/pullrequests/route'),
});

const WorkspaceSearch = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/workspace/sections/search/route'),
});

const WorkspaceRepositories = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/profile/sections/repositories/route'),
});

const WorkspaceProjects = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/workspace/sections/projects/resource-router-route'),
});

const WorkspaceSettingsConnect = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/containers/settings-connect-iframe'
    ),
});

const WorkspaceSettingsForge = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/forge/workspace-settings-menu-page/workspace-settings-menu-page'
    ),
});

const WorkspaceSettingsIframe = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/containers/settings-iframe'
    ),
});

const WorkspaceSettingsGroup = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/workspace/sections/settings/components/group'),
});

const WorkspaceSettingsGroups = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/workspace/sections/settings/components/groups'),
});

const WorkspaceSettingsRoot = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/settings-index'
    ),
});

const WorkspaceSettingsUserDirectory = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/user-directory'
    ),
});

const WorkspaceSettingsAtlassianIntelligence = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/atlassian-intelligence'
    ),
});

const WorkspaceSettingsCompass = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/compass/compass-workspace-settings-page'
    ),
});

const WorkspaceSettingsMergeChecks = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/merge-checks/merge-checks-workspace-settings-page'
    ),
});

const PipelinesWorkspaceSettingsRedirectSettingsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/pipelines/redirect/to-workspace-settings'
    ),
});

const PipelinesWorkspaceSettingsRedirectRunnersLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/components/pipelines/redirect/to-workspace-settings'
    ),
});

export const getWorkspaceRoutesConfig = ({
  isWorkspaceSettingsIndexEnabled,
}: RouteFeatures): RouteConfig => {
  return {
    [ROUTE_NAME.ROOT]: {
      component: RootRedirect,
      resources: [],
    },
    [ROUTE_NAME.WORKSPACE_CREATE]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceCreatePage,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.WORKSPACE_OVERVIEW]: {
      // @ts-ignore: requires RouteContext type on component
      component: DashboardOverview,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        dashboardOverviewViewResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_REPOSITORIES]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceRepositories,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceRepositoriesResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_ROOT]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceRepositories,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceRepositoriesResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.PROFILE_REPOSITORIES]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceRepositories,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceRepositoriesResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_PROJECTS]: {
      component: WorkspaceProjects,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceProjectsResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_PULL_REQUESTS]: {
      component: WorkspacePullRequests,
      layout: WorkspaceLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspacePullRequestsResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SEARCH]: {
      component: WorkspaceSearch,
      resources: [
        workspaceSectionResource,
        workspaceNavigationResource,
        loadGlobalResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_ROOT]: {
      // @ts-ignore: requires RouteContext type on component
      component: isWorkspaceSettingsIndexEnabled
        ? WorkspaceSettingsRoot
        : WorkspaceSettingsIframe,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_GROUP]: {
      component: WorkspaceSettingsGroup,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_GROUPS]: {
      component: WorkspaceSettingsGroups,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_USER_DIRECTORY]: {
      component: WorkspaceSettingsUserDirectory,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_ATLASSIAN_INTELLIGENCE]: {
      component: WorkspaceSettingsAtlassianIntelligence,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_COMPASS]: {
      component: WorkspaceSettingsCompass,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
        compassResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_MERGE_CHECKS]: {
      component: WorkspaceSettingsMergeChecks,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        customMergeChecksConfigRouteResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PERMISSIONS]: {
      component: WorkspaceSettingsPermissionsLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        loadWorkspaceSettingsPermissionsResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_CONNECT_ADMIN]: {
      component: WorkspaceSettingsConnect,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_CONNECT_CONFIGURE]: {
      component: WorkspaceSettingsConnect,
      layout: WorkspaceSettingsLayout,
      resources: [loadGlobalResource, workspaceSectionResource],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_FORGE_PAGE]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceSettingsForge,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_ACCESS_TOKENS]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceSettingsAccessTokensLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_REDIRECT_SETTINGS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesWorkspaceSettingsRedirectSettingsLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_REDIRECT_RUNNERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesWorkspaceSettingsRedirectRunnersLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_RUNNERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesWorkspaceRunnersPageLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        loadPipelinesWorkspaceRunnersResource,
        workspaceNavigationResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_VARIABLES]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesWorkspaceVariablesPageLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        loadPipelinesWorkspaceVariablesResource,
        workspaceNavigationResource,
        loadThirdPartySecretsProviderResource,
      ],
    },
    [ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_DYNAMIC_PIPELINES]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesWorkspaceDynamicPipelinesPageLoadable,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        loadWorkspaceDynamicPipelinesResource,
        workspaceNavigationResource,
      ],
    },

    [ROUTE_NAME.WORKSPACE_SETTINGS_IFRAME]: {
      // @ts-ignore: requires RouteContext type on component
      component: WorkspaceSettingsIframe,
      layout: WorkspaceSettingsLayout,
      resources: [
        loadGlobalResource,
        workspaceSectionResource,
        workspaceNavigationResource,
      ],
    },
  };
};
