import { createResource } from 'react-resource-router';

import {
  TestExecution,
  type TestExecutionResponse,
} from 'src/components/pipelines/models/TestReport';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';

import { fetchData } from './utils';

export type Params = {
  testCase?: string;
  repositoryFullSlug?: string;
};

export type Query = {
  page?: string;
  fullyQualifiedName?: string;
  AverageDuration?: string;
};

export function getTestExecutionsUrl(params: Params, query: Query) {
  return `${envBaseUrl('/!api/internal')}/repositories/${
    params.repositoryFullSlug
  }/test-cases/${query.fullyQualifiedName}/executions?page=${query.page || 1}`;
}

export const loadTestExecutionsResource = createResource({
  type: 'pipelinesTestExecutions',
  getKey: ({ match, query }) => `${getTestExecutionsUrl(match.params, query)}`,
  maxAge: 60000,
  getData: async ({ match, query }) => {
    const testExecutionData = await fetchData(
      getTestExecutionsUrl(match.params, query),
      'test_executions_resource_exception'
    );

    return {
      page: testExecutionData.page,
      pagelen: testExecutionData.pagelen,
      size: testExecutionData.size,
      values: testExecutionData.values.map(
        (testExecution: TestExecutionResponse) =>
          new TestExecution(testExecution)
      ),
    };
  },
});
