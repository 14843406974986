import { N30, N900, R400 } from '@atlaskit/theme/colors';
import { borderRadius as getBorderRadius,
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
gridSize as getGridSize } from '@atlaskit/theme/constants';
export var width = {
  values: ['small', 'medium', 'large', 'x-large'],
  widths: {
    small: 400,
    medium: 600,
    large: 800,
    'x-large': 968
  },
  defaultValue: 'medium'
};
export var gutter = 60;
var gridSize = getGridSize();
export var borderRadius = getBorderRadius();
export var verticalOffset = gridSize * 2;
export var padding = gridSize * 3;
export var keylineHeight = 2;
export var keylineColor = "var(--ds-border, ".concat(N30, ")");
export var textColor = "var(--ds-text, ".concat(N900, ")");
export var iconColor = {
  danger: "var(--ds-icon-danger, ".concat(R400, ")"),
  warning: "var(--ds-icon-warning, #D97008)"
};