import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _ACCEPT_ALL_PREFERENC, _ONLY_NECESSARY_PREFE, _objectSpread2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ContextualConsentProperty, PreferenceCategory } from './types';
export var LEGACY_ATLASSIAN_GDPR_COOKIE_KEY = 'atl-gdpr-consent';
export var ATLASSIAN_CONSENT_COOKIE_KEY = 'atl-bsc-consent-token';
export var ATLASSIAN_SHOW_BANNER_COOKIE_KEY = 'atl-bsc-show-banner';
// TODO: DEPRECATED: Locale no longer supported, but to ensure backwards compatibility, we'll keep this exported const
export var ATLASSIAN_CURRENT_LOCALE_COOKIE_KEY = 'atl-bsc-current-locale';
export var ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY = 'atl-bsc-consent-token-fallback';
export var TESTING_TOKEN_OVERRIDE_COOKIE_KEY = 'atl-testing-consents';
export var ACCEPT_ALL_PREFERENCES = (_ACCEPT_ALL_PREFERENC = {}, _defineProperty(_ACCEPT_ALL_PREFERENC, PreferenceCategory.StrictlyNecessary, true), _defineProperty(_ACCEPT_ALL_PREFERENC, PreferenceCategory.Functional, true), _defineProperty(_ACCEPT_ALL_PREFERENC, PreferenceCategory.Marketing, true), _defineProperty(_ACCEPT_ALL_PREFERENC, PreferenceCategory.Analytics, true), _defineProperty(_ACCEPT_ALL_PREFERENC, PreferenceCategory.Unknown, true), _ACCEPT_ALL_PREFERENC);
export var ONLY_NECESSARY_PREFERENCES = (_ONLY_NECESSARY_PREFE = {}, _defineProperty(_ONLY_NECESSARY_PREFE, PreferenceCategory.StrictlyNecessary, true), _defineProperty(_ONLY_NECESSARY_PREFE, PreferenceCategory.Functional, false), _defineProperty(_ONLY_NECESSARY_PREFE, PreferenceCategory.Marketing, false), _defineProperty(_ONLY_NECESSARY_PREFE, PreferenceCategory.Analytics, false), _defineProperty(_ONLY_NECESSARY_PREFE, PreferenceCategory.Unknown, false), _ONLY_NECESSARY_PREFE);

// Default preferences are now strict/"Only Necessary" by default for all users
// and for sake of ease, we'll classify them as "logged out"
export var DEFAULT_PREFERENCES = _objectSpread(_objectSpread({}, ONLY_NECESSARY_PREFERENCES), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, ContextualConsentProperty.ConsentsAreDefault, true), _defineProperty(_objectSpread2, ContextualConsentProperty.UserIsAuthenticated, false), _defineProperty(_objectSpread2, ContextualConsentProperty.ConsentDataUnavailable, true), _objectSpread2));
export var V001_SCHEMA_KEY = '001';
export var V002_SCHEMA_KEY = '002';
export var V003_SCHEMA_KEY = '003';