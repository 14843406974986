import qs from 'qs';

import { ISO8601DateString, UrlPieces } from 'src/types/pull-request';
import { ContextLineRanges } from 'src/utils/validate-context-lines';

import { qsDefaultOptions, buildCommentUrl } from './url-utils';

const baseUrl = '!api/internal/repositories';
const DEFAULT_ACTIVITY_PAGELEN = 50;

type PaginatedUrlPieces = UrlPieces & { pagelen?: number };

export const internal = {
  activity: ({
    owner,
    slug,
    id,
    pagelen = DEFAULT_ACTIVITY_PAGELEN,
  }: PaginatedUrlPieces) => {
    const qsParams = {
      pagelen,
      fields:
        '+values.attachment.uuid,+values.update.source.commit.parents.hash',
    };
    const query = qs.stringify(qsParams, qsDefaultOptions);
    const url = `/${baseUrl}/${owner}/${slug}/pullrequests/${id}/activity`;

    return `${url}${query}`;
  },

  orderedCommentsFetch: (
    user: string,
    repoSlug: string,
    pullRequestId: string | number
  ) => {
    const fields =
      '+values.user.account_status,+values.resolution.*,+values.inline.*';
    return buildCommentUrl(user, repoSlug, pullRequestId, undefined, {
      baseUrl,
      extraQueryStringParams: {
        fields,
        pagelen: 100,
        sort: 'id',
      },
    });
  },

  conflicts: (user: string, repoSlug: string, pullRequestId: string | number) =>
    `/!api/internal/repositories/${user}/${repoSlug}/pullrequests/${pullRequestId}/conflicts`,

  contextFromSrcPreviewMerge: (
    pieces: {
      owner: string;
      slug: string;
      destinationHash: string;
      filepath: string;
    },
    lineNums: ContextLineRanges
  ) => {
    const lineNumParams = {
      fromline: lineNums.startingFrom,
      toline: lineNums.endingFrom,
    };
    const { owner, slug, destinationHash, filepath } = pieces;
    const url = `/!api/internal/repositories/${owner}/${slug}/src/${destinationHash}/${encodeURIComponent(
      filepath
    )}`;

    const query = qs.stringify(lineNumParams, {
      addQueryPrefix: true,
      skipNulls: true,
    });

    return `${url}${query}`;
  },

  // TODO: remove contextFromSrcPreviewMerge above and rename this to `context`
  // once we fully switch to topic diffs and consolidate with PR expand context logic
  // we are fetching context from the destination right now because in preview-merge diffs, there may
  // be changes in context lines in the destination that are not present on the source branch
  contextFromSrcTopicDiff: (
    pieces: {
      owner: string;
      slug: string;
      sourceHash: string;
      filepath: string;
    },
    lineNums: ContextLineRanges
  ) => {
    const lineNumParams = {
      fromline: lineNums.startingTo,
      toline: lineNums.endingTo,
    };

    const { owner, slug, sourceHash, filepath } = pieces;
    const url = `/!api/internal/repositories/${owner}/${slug}/src/${sourceHash}/${encodeURIComponent(
      filepath
    )}`;

    const query = qs.stringify(lineNumParams, {
      addQueryPrefix: true,
      skipNulls: true,
    });

    return `${url}${query}`;
  },

  imageUpload: (owner: string, slug: string) =>
    `/xhr/${owner}/${slug}/image-upload/`,

  mergeChecks: (
    user: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/internal/repositories/${user}/${repoSlug}/pullrequests/${pullRequestId}/merge-restrictions`,

  pendingMerge: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/post-build-merge`,

  watch: (owner: string, repoSlug: string, pullRequestId: string | number) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/watch`,

  commentById: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    commentId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/comments/${commentId}`,

  likeComment: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    commentId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/comments/${commentId}/likes`,

  commentLikes: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/likes`,

  updates: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    since: Date | ISO8601DateString
  ) => {
    const sinceVal = typeof since === 'string' ? since : since.toISOString();
    return `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/updates?since=${encodeURIComponent(
      sinceVal
    )}`;
  },

  branchSyncInfo: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/branch-sync-info`,

  revert: (owner: string, repoSlug: string, pullRequestId: string | number) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/revert`,

  updateReviewer: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    userAaid: string
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/reviewers/${userAaid}`,

  updatePullRequestSourceBranch: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/update-source-branch`,

  // multi-comment review endpoints
  review: (owner: string, repoSlug: string, pullRequestId: string | number) =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/review`,
  // returns list of pending reviewers for a PR
  pendingReviews: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ): string =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/pendingreviews`,

  // pr review groups
  reviewGroups: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ): string =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/reviewers/groups`,

  // iterative review
  checkpoint: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ): string =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/checkpoint`,

  // pull request labels list
  prLabels: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number
  ): string =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/labels`,

  // pull request label
  prLabel: (
    owner: string,
    repoSlug: string,
    pullRequestId: string | number,
    label: string
  ): string =>
    `/!api/internal/repositories/${owner}/${repoSlug}/pullrequests/${pullRequestId}/labels/${label}`,

  // pull request labels list
  labels: (owner: string): string =>
    `/!api/internal/workspaces/${owner}/pullrequest-labels`,
};
