import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import { ErrorMessage, Field } from '@atlaskit/form';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import Textfield from '@atlaskit/textfield';

import { useWorkspaceThirdPartySecretsProvider } from 'src/components/pipelines/components/third-party-secrets/components/secret-provider-endpoints';
import { messages } from 'src/components/pipelines/components/third-party-secrets/components/secrets.i18n';
import { useFlag } from 'src/hooks/flag';

import { isValidURL } from '../../pipelines/utils';

const fieldWidth = xcss({ width: '42%' });
const secretProviderPadding = xcss({ paddingTop: 'space.075' });
export const LOADING = '1';
export const FAILED_LOAD = '2';

const ThirdPartySecretProviderConfiguration = (): React.ReactElement => {
  const {
    selectedAppAri,
    setThirdPartySecretsProvider,
    deleteThirdPartySecretsProvider,
    isThirdPartySecretsProviderResourceLoading,
  } = useWorkspaceThirdPartySecretsProvider();

  const [providerUrl, setProviderUrl] = useState(selectedAppAri || '');
  const [isDisabled, setIsDisabled] = useState(providerUrl !== '');
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const { showErrorFlag } = useFlag();

  const deleteProvider = useCallback(async () => {
    if (await deleteThirdPartySecretsProvider()) {
      setIsDisabled(false);
      setProviderUrl('');
    }
  }, [deleteThirdPartySecretsProvider]);

  const addProvider = useCallback(async () => {
    if (!isValidURL(providerUrl)) {
      setIsInvalidUrl(true);
    } else if (providerUrl !== selectedAppAri) {
      if (await setThirdPartySecretsProvider(providerUrl)) {
        setIsDisabled(true);
      }
    }
  }, [providerUrl, selectedAppAri, setThirdPartySecretsProvider]);

  useEffect(() => {
    if (providerUrl === LOADING) {
      setProviderUrl(selectedAppAri);
    } else if (selectedAppAri === FAILED_LOAD) {
      setIsDisabled(true);
      setProviderUrl('');
    } else {
      setIsDisabled(selectedAppAri !== '');
    }
  }, [providerUrl, selectedAppAri, showErrorFlag]);

  const onNameChange = useCallback((event, fieldOnChange) => {
    setProviderUrl(event.target.value);
    setIsInvalidUrl(false);
    fieldOnChange(event);
  }, []);

  const { formatMessage } = useIntl();

  return (
    <Box xcss={secretProviderPadding}>
      <Field
        label={formatMessage(messages.fieldLabel)}
        name="secret-provider-field"
      >
        {({ fieldProps }: any) => (
          <Fragment>
            <Inline alignBlock="center" space="space.300">
              <Stack xcss={fieldWidth}>
                <Textfield
                  {...fieldProps}
                  isRequired={false}
                  value={providerUrl === LOADING ? '' : providerUrl}
                  isDisabled={isDisabled}
                  onChange={event => onNameChange(event, fieldProps.onChange)}
                />
              </Stack>

              <Button
                isDisabled={selectedAppAri === FAILED_LOAD}
                overlay={
                  isThirdPartySecretsProviderResourceLoading ? (
                    <Spinner testId="spinner-id" size="small" />
                  ) : null
                }
                onClick={selectedAppAri === '' ? addProvider : deleteProvider}
              >
                {selectedAppAri === ''
                  ? formatMessage(messages.add)
                  : formatMessage(messages.remove)}
              </Button>
            </Inline>

            {isInvalidUrl && (
              <ErrorMessage testId={messages.invalidUrlMessage.id}>
                <Box aria-live="polite">
                  {formatMessage(messages.invalidUrlMessage)}
                </Box>
              </ErrorMessage>
            )}
          </Fragment>
        )}
      </Field>
    </Box>
  );
};

export { ThirdPartySecretProviderConfiguration };
