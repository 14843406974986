import { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';
import { useResource } from 'react-resource-router';

import { useFlag } from 'src/hooks/flag';
import { useParams } from 'src/router/hooks';
import { request } from 'src/utils/http-request';

import {
  Params,
  ThirdPartySecretsProvider,
  UseThirdPartySecretsProvider,
} from '../components/third-party-secrets/components/secret-provider-endpoints';
import { messages } from '../components/third-party-secrets/components/secrets.i18n';
import { LOADING } from '../pages/admin/variables/SecretProviderConfiguration';
import { capturePipelinesExceptionWithTags } from '../utils/sentry';

export function useThirdPartySecretsProvider({
  resource,
  getRequestUrl,
}: UseThirdPartySecretsProvider): ThirdPartySecretsProvider {
  const { formatMessage } = useIntl();
  const params = useParams<Params>();

  const {
    data: resourceData,
    error: resourceError,
    loading: isResourceLoading,
    update: updateResource,
  } = useResource(resource);

  const { appAri } = resourceData || {
    appAri: LOADING,
  };

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { showErrorFlag, showSuccessFlag } = useFlag({ enableForPdvs: true });

  const isThirdPartySecretsProviderResourceLoading =
    isResourceLoading || isRequestLoading;

  useEffect(() => {
    if (resourceError) {
      capturePipelinesExceptionWithTags(resourceError as Error, {
        segment: 'pipelines_fetch_third_party_secrets_provider_exception',
      });
      showErrorFlag(formatMessage(messages.failedFetchErrorFlagMsg));
    }
    // Intendedly excluded `showErrorFlag` as it may report same error on re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceError]);

  const setThirdPartySecretsProvider = useCallback(
    async (selectedAppAri: string) => {
      setIsRequestLoading(true);
      try {
        const resp = await request(getRequestUrl(params), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: selectedAppAri }),
        });
        updateResource(data => ({
          ...data,
          appAri: resp.url,
        }));
        showSuccessFlag(formatMessage(messages.successfullySetFlagMsg));
        return true;
      } catch (e) {
        capturePipelinesExceptionWithTags(e, {
          segment: 'pipelines_set_third_party_secrets_provider_exception',
        });
        showErrorFlag(formatMessage(messages.setFailedErrorFlagMsg));
        return false;
      } finally {
        setIsRequestLoading(false);
      }
    },
    [
      showErrorFlag,
      formatMessage,
      getRequestUrl,
      params,
      updateResource,
      showSuccessFlag,
    ]
  );

  const deleteThirdPartySecretsProvider = useCallback(async () => {
    setIsRequestLoading(true);
    try {
      await request(getRequestUrl(params), {
        method: 'DELETE',
      });
      updateResource(data => ({
        ...data,
        appAri: '',
      }));
      showSuccessFlag(formatMessage(messages.successfullyDeletedFlagMsg));
      return true;
    } catch (e) {
      capturePipelinesExceptionWithTags(e, {
        segment: 'pipelines_delete_third_party_secrets_provider_exception',
      });
      showErrorFlag(formatMessage(messages.deleteFailedErrorFlagMsg));
      return false;
    } finally {
      setIsRequestLoading(false);
    }
  }, [
    showErrorFlag,
    formatMessage,
    getRequestUrl,
    params,
    updateResource,
    showSuccessFlag,
  ]);

  return {
    selectedAppAri: appAri,
    setThirdPartySecretsProvider,
    deleteThirdPartySecretsProvider,
    isThirdPartySecretsProviderResourceLoading,
  };
}
