import { token } from '@atlaskit/tokens';

import { convertPipelineStatus } from '../utils/convertStatus';
import { convertTerminatedBy } from '../utils/convertTerminatedBy';
import { flatten } from '../utils/flatten';
import { pick } from '../utils/pick';

import { Commit } from './Commit';
import { ModelBase } from './ModelBase';
import { PipelineError } from './PipelineError';
import { ConfigurationSources, PipelineState } from './PipelineState';
import {
  PipelinePullRequestTarget,
  PipelineTarget,
  TARGET_SELECTOR_TYPE,
} from './PipelineTarget';
import { PullRequest } from './PullRequest';
import { User } from './User';

/* maps to https://bitbucket.org/
	  bitbucketci/rest-service/src/master/model/src/main/java/com/atlassian/pipelines/rest/model/v1/step/state/
*/
type PipelineStatusPending = 'PENDING';
type PipelineStatusParsing = 'PARSING';
type PipelineStatusInProgress = 'HALTED' | 'PAUSED' | 'RUNNING';
type PipelineStatusCompleted = 'ERROR' | 'FAILED' | 'STOPPED' | 'SUCCESSFUL';
type PipelineStatusCustom = 'IN_PROGRESS' | 'USER_ERROR' | 'SYSTEM_ERROR';
export type PipelineStatusType =
  | PipelineStatusPending
  | PipelineStatusParsing
  | PipelineStatusInProgress
  | PipelineStatusCompleted
  | PipelineStatusCustom;

export const PipelineStatus: {
  [K in PipelineStatusType]: { text: string; color: string };
} = {
  PENDING: {
    text: 'Pending',
    color: token('color.icon.selected', '#0065FF'),
  },
  PARSING: {
    text: 'Pending',
    color: token('color.icon.selected', '#0065FF'),
  },
  PAUSED: {
    text: 'Paused',
    color: token('color.icon.subtle', '#7A869A'),
  },
  HALTED: {
    text: 'Halted',
    color: token('color.icon.warning', '#FF8B00'),
  },
  RUNNING: {
    text: 'Running',
    color: token('color.icon.information', '#36B27E'),
  },
  IN_PROGRESS: {
    text: 'Running',
    color: token('color.icon.information', '#0065FF'),
  },
  ERROR: {
    text: 'Error',
    color: token('color.icon.danger', '#FF5630'),
  },
  USER_ERROR: {
    text: 'Configuration error',
    color: token('color.icon.danger', '#FF5630'),
  },
  SYSTEM_ERROR: {
    text: 'Error',
    color: token('color.icon.danger', '#FF5630'),
  },
  FAILED: {
    text: 'Failed',
    color: token('color.icon.danger', '#FF5630'),
  },
  STOPPED: {
    text: 'Stopped',
    color: token('color.icon.subtle', '#7A869A'),
  },
  SUCCESSFUL: {
    text: 'Successful',
    color: token('color.icon.success', '#36B27E'),
  },
};

export const SYNC_STATUS: {
  [key: string]: string;
} = {
  PARSING: 'PARSING',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
};
interface PipelineTriggerMetadata {
  pipeline_uuid?: { type: string; uuid: string };
  step_uuid?: { uuid: string };
  pipeline_run_uuid?: string;
}
export class Pipeline extends ModelBase {
  readonly uuid: string = '';
  readonly status: string = '';
  readonly error: any = null;
  readonly isStopping: boolean = false;
  readonly isCreating: boolean = false;
  readonly expired: boolean = false;
  readonly has_variables: boolean = false;
  readonly build_seconds_used: number = 0;
  readonly duration_in_seconds: number = 0;
  readonly build_number: number = 0;
  readonly completed_on: string = '';
  readonly created_on: string = '';
  readonly creator: User = new User();
  readonly run_number: number = 0;
  readonly runUuid: string = '';
  readonly run_creation_date: string = '';
  readonly target: PipelineTarget = {};
  readonly trigger: {
    type?: string;
    name?: string;
    metadata?: PipelineTriggerMetadata;
  } = {};
  readonly state: PipelineState = {
    type: '',
    name: '',
  };
  readonly terminatedBy: string = '';
  readonly configuration_sources: ConfigurationSources[] = [
    {
      source: '',
      uri: '',
    },
  ];

  // flattened properties
  readonly 'trigger.type': string = '';
  readonly 'target.commit': Commit = new Commit();
  readonly 'target.pullrequest': PullRequest = new PullRequest();
  readonly 'target.ref_name': string = '';
  readonly 'target.ref_type': string = '';
  readonly 'target.selector.type': TARGET_SELECTOR_TYPE = 'default';
  readonly 'target.selector.pattern': string = '';
  readonly 'target.source': string = '';

  readonly 'labels.atlassian-shadowed.commit': string = '';
  readonly 'labels.atlassian-shadowed.committer': string = '';
  readonly 'labels.atlassian-shadowed.project': string = '';
  readonly 'labels.atlassian-shadowed.repository': string = '';
  constructor(props: Partial<Pipeline> = {}) {
    super(props);
    let status = convertPipelineStatus(props.state);
    const terminatedBy = convertTerminatedBy(props.state);
    let { error } = props;
    if (status === 'RUNNING') {
      status = 'IN_PROGRESS';
    }

    if (
      ['ERROR', 'FAILED', 'SYSTEM_ERROR'].indexOf(status) !== -1 &&
      props?.state?.result?.error
    ) {
      status = 'USER_ERROR';
      error = new PipelineError(props?.state?.result?.error);
    }

    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
      ...(props.configuration_sources
        ? {
            configuration_sources: props.configuration_sources,
          }
        : {}),
      ...(props.state
        ? {
            state: props.state,
          }
        : {}),
      ...(props.target ? { target: props.target } : {}),
      ...(props.target && props.target.commit
        ? { 'target.commit': new Commit(props.target.commit) }
        : {}),
      ...(props.target && props.target.type === 'pipeline_pullrequest_target'
        ? {
            'target.pullrequest': new PullRequest(
              (props.target as PipelinePullRequestTarget).pullrequest
            ),
          }
        : {}),
      creator:
        props.creator?.class_type === 'User'
          ? props.creator
          : new User(props.creator),
      status,
      terminatedBy,
      error,
      trigger: {
        ...props.trigger,
        metadata: props.trigger?.metadata || undefined,
      },
    });

    Object.freeze(this);
  }

  get isScheduled(): boolean {
    return this.triggerType === 'pipeline_trigger_schedule';
  }

  get isManual(): boolean {
    return this.triggerType === 'pipeline_trigger_manual';
  }

  get isSkipped(): boolean {
    return this.status === 'SKIPPED' || this.status === 'HALTED';
  }

  get isHalted(): boolean {
    return this.status === 'HALTED';
  }

  get isStopped(): boolean {
    return this.status === 'STOPPED';
  }

  get isParsing(): boolean {
    return this.status === 'PARSING';
  }

  get isComplete(): boolean {
    return (
      [
        'SUCCESSFUL',
        'FAILED',
        'ERROR',
        'PAUSED',
        'HALTED',
        'USER_ERROR',
        'SYSTEM_ERROR',
        'STOPPED',
        'SKIPPED',
      ].indexOf(this.status) !== -1
    );
  }

  get isFailed(): boolean {
    return (
      ['ERROR', 'FAILED', 'SYSTEM_ERROR', 'USER_ERROR'].indexOf(this.status) !==
      -1
    );
  }

  get canRerunSteps(): boolean {
    return (
      this.isComplete &&
      this.status !== 'SUCCESSFUL' &&
      this.status !== 'PAUSED' &&
      this.status !== 'HALTED' &&
      !this.expired
    );
  }

  get canRerunPipeline(): boolean {
    return !this.has_variables;
  }

  get isRunning(): boolean {
    return !!SYNC_STATUS[this.status];
  }

  get triggerType(): string {
    return this['trigger.type'];
  }

  get commit(): Commit {
    return this['target.commit'];
  }

  get pullrequest(): PullRequest {
    return this['target.pullrequest'];
  }

  get revision(): string {
    return this['target.commit'].hash;
  }

  get message(): string {
    return this['target.commit'].message || 'Commit message unavailable';
  }

  get buildNumber(): number {
    return this.build_number;
  }
  get configurationSources(): ConfigurationSources[] {
    return this.configuration_sources;
  }

  get creationDate(): string {
    return this.created_on;
  }

  get completionDate(): string {
    return this.completed_on;
  }

  get runCreationDate(): string {
    return this.run_creation_date;
  }

  get buildSecondsUsed(): number {
    return Number(this.build_seconds_used || 0);
  }

  get durationInSeconds(): number {
    return Number(this.duration_in_seconds || 0);
  }

  get refName(): string {
    return this['target.ref_name'];
  }

  get refType(): string {
    return this['target.ref_type'] || '';
  }

  get selectorPattern(): string {
    return this['target.selector.pattern'];
  }

  get selectorType(): string {
    return this['target.selector.type'] || '';
  }

  get sourceBranch(): string {
    if (this.isBranchPipeline) {
      return this.refName;
    }
    if (this.isPullRequestPipeline) {
      return this['target.source'];
    }
    return '';
  }

  get isBranchPipeline(): boolean {
    return this.refType === 'branch' || this.refType === 'named_branch';
  }

  get isPullRequestPipeline(): boolean {
    return this.pullrequest.type === 'pullrequest';
  }

  get customName(): string {
    return this.selectorType.toLowerCase() === 'custom'
      ? `custom: ${this.selectorPattern}`
      : '';
  }

  get statusIcon(): string {
    return this.status === 'PENDING' || this.status === 'PARSING'
      ? 'IN_PROGRESS'
      : this.status;
  }

  get statusText(): string {
    return this.status ? (PipelineStatus as any)[this.status].text : '';
  }

  get statusColor(): string {
    return this.status ? (PipelineStatus as any)[this.status].color : '';
  }

  get shadowedCommit(): string {
    return this['labels.atlassian-shadowed.commit'];
  }

  get shadowedCommitter(): string {
    return this['labels.atlassian-shadowed.committer'];
  }

  get shadowedProject(): string {
    return this['labels.atlassian-shadowed.project'];
  }

  get shadowedRepository(): string {
    return this['labels.atlassian-shadowed.repository'];
  }
  get parentPipelineUuid(): string | undefined {
    return this.trigger.metadata?.pipeline_uuid?.uuid;
  }
}
