import qs from 'qs';

import { Privacy } from 'src/sections/workspace/sections/settings/components/group/privacy-dropdown';
import escapeBbqlString from 'src/utils/escape-bbql-string';

const urls = {
  api: {
    internal: {
      create: `/!api/internal/workspaces/`,
      compass_banner_email_notification: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/compass-banner-email-notification`,
      RUD: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/`,
      changeAvatar: (slug: string) =>
        `/!api/internal/workspaces/${slug}/avatar`,
      getUserGroupAccess: (
        workspaceSlug: string,
        userUuid: string,
        options: { page?: number; pagelen?: number }
      ) => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/access/${userUuid}/groups`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      getUserRepoAccess: (
        workspaceSlug: string,
        userUuid: string,
        options: { page?: number; pagelen?: number }
      ) => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/access/${userUuid}/repos`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      inheritedPermissions: (
        workspaceSlug: string,
        options?: {
          page?: number;
          q?: string;
        }
      ) => {
        const url = `/!api/internal/inherited-workspace-privileges/${workspaceSlug}/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      defaultGroup: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/default-group`,
      groups: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/groups/`,
      group: (workspaceSlug: string, groupSlug?: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/groups/${groupSlug}`,
      atlassianIntelligence: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/atlassian-intelligence/`,
      mergeChecksActivation: (workspaceSlug: string) =>
        `/!api/internal/checks/workspaces/${workspaceSlug}/activate/`,
      plan: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/plan`,
      members: (
        workspaceSlug: string,
        options?: {
          exclude_group?: string;
          search?: string;
          repoUuid?: string;
          page?: number;
          usersOnPlan?: boolean;
        }
      ) => {
        const { exclude_group, page, search, repoUuid, usersOnPlan } =
          options || {};
        const url = `/!api/internal/workspaces/${workspaceSlug}/members`;
        return `${url}${qs.stringify(
          {
            exclude_group: exclude_group || undefined,
            page: page || undefined,
            search: search || undefined,
            repo_id: repoUuid || undefined,
            on_plan: usersOnPlan || undefined,
          },
          { skipNulls: true, addQueryPrefix: true }
        )}`;
      },
      groupRepositoryPrivileges: (
        workspaceSlug: string,
        groupSlug: string,
        privacy: Privacy | null,
        options?: {
          page?: number;
          pagelen?: number;
          privilege?: BB.Privilege | null;
          search?: string;
          sort?: 'name' | '-name';
        }
      ) => {
        let url = `/!api/internal/group-repo-privileges/${workspaceSlug}/${groupSlug}`;
        url = `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;

        if (privacy) {
          if (privacy === Privacy.public) {
            url = `${url}&q=is_private=False`;
          } else {
            url = `${url}&q=is_private=True`;
          }
        }
        return url;
      },
      groupResourcePermissions: (
        workspaceSlug: string,
        groupSlug: string,
        privacy: Privacy | null,
        options?: {
          page?: number;
          pagelen?: number;
          privilege?: BB.Privilege | null;
          search?: string;
          sort?: 'name' | '-name';
        }
      ) => {
        let url = `/!api/internal/group-resource-permissions/${workspaceSlug}/${groupSlug}`;
        url = `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;

        if (privacy) {
          if (privacy === Privacy.public) {
            url = `${url}&q=is_private=False`;
          } else {
            url = `${url}&q=is_private=True`;
          }
        }
        return url;
      },
      groupMembers: (
        workspaceSlug: string,
        groupSlug?: string,
        userUuid?: string,
        options?: {
          search?: string;
          page?: number;
          pagelen?: number;
          sort?: string;
        }
      ) => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/groups/${groupSlug}/members/${
          userUuid || ''
        }`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      groupsIndex: (
        workspaceSlug: string,
        options?: {
          pagelen?: number;
          page?: number;
          search?: string;
          sort?: string;
          fields?: string;
          has_access?: boolean;
        }
      ) => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/groups`;
        return `${url}${qs.stringify(options || {}, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      navigation: (workspaceSlug: string) =>
        `/!api/internal/menu/workspace/${workspaceSlug}`,
      forgeMenuItems: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/forge/menu-items/`,
      overviewViewState: (workspaceSlug: string): string =>
        `/!api/internal/workspaces/${workspaceSlug}/overview-view-state`,
      pullRequests: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/pullrequests`,
      pullRequestBuildStatuses: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/commits/statuses`,
      userList: (workspaceSlug: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/user-list`,
      userRepoPrivileges: (repoFullName: string, userUuid: string) =>
        `/!api/internal/privileges/${repoFullName}/${userUuid}/`,
      workspaceAccess: (workspaceSlug: string, userUuid: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/access/${userUuid}`,
      getCompassCloudIdsForWorkspace: (workspaceSlug: string): string => {
        return `/!api/internal/workspaces/${workspaceSlug}/compass/workspace-connection`;
      },
      getCompassCloudIdsForRepository: (
        workspaceSlug: string,
        repositorySlug: string
      ): string => {
        return `/!api/internal/repositories/${workspaceSlug}/${repositorySlug}/compass/workspace-connection`;
      },
      customMergeChecks: (workspaceSlug: string): string =>
        `/!api/internal/workspaces/${workspaceSlug}/checks`,
    },
    v10: {
      groups: (workspaceSlug: string, groupSlug?: string) =>
        `/!api/1.0/groups/${workspaceSlug}/${groupSlug}`,
      invitations: (workspaceSlug: string) =>
        `/!api/1.0/users/${workspaceSlug}/invitations`,
    },
    v20: {
      get: (uuid?: string) => `/!api/2.0/workspaces${uuid ? `/${uuid}` : ''}`,
      projects: (workspaceSlugOrUuid: string, name?: string) => {
        const url = `/!api/2.0/workspaces/${workspaceSlugOrUuid}/projects`;
        if (name) {
          const q = `name ~ "${escapeBbqlString(name)}"`;
          return `${url}?${qs.stringify(
            { q, sort: 'name' },
            { skipNulls: true }
          )}`;
        }
        return url;
      },
      project: (workspaceSlugOrUuid: string, key: string) => {
        const url = `/!api/2.0/workspaces/${workspaceSlugOrUuid}/projects/${key}`;
        return url;
      },
      repositories: (
        workspaceSlugOrUuid: string,
        options?: { name?: string; pagelen?: number; sort?: string }
      ) => {
        const { pagelen, name, sort } = options || {};
        const url = `/!api/2.0/repositories/${workspaceSlugOrUuid}`;
        const q = name ? `name~"${escapeBbqlString(name)}"` : null;

        return `${url}${qs.stringify(
          { pagelen, q, sort: sort || 'name' },
          { skipNulls: true, addQueryPrefix: true }
        )}`;
      },
      members: (uuid: string) => `/!api/2.0/workspaces/${uuid}/members`,
    },
  },
  ui: {
    landing: (slug: string) => `/${slug}`,
    overview: (slug: string) => `/${slug}/workspace/overview`,
    getLinkToRepos: (slug: string) => `/${slug}/workspace/repositories`,
    getLinkToGroup: (
      workspaceSlug: string,
      groupSlug: string,
      options?: { tab?: 'repositories' }
    ) => {
      const url = `/${workspaceSlug}/workspace/settings/groups/${groupSlug}`;
      return `${url}${qs.stringify(options, {
        skipNulls: true,
        addQueryPrefix: true,
      })}`;
    },
    projectCreate: (workspaceSlug?: string): string =>
      `/account/projects/create${
        workspaceSlug ? `?workspace=${workspaceSlug}` : ''
      }`,
    permissions: (workspaceSlug: string): string =>
      `/${workspaceSlug}/workspace/settings/permissions`,
    customMergeChecks: (workspaceSlug: string): string =>
      `/${workspaceSlug}/workspace/settings/merge-checks`,
  },
  external: {
    contactUsLink: 'https://support.atlassian.com/bitbucket-cloud/',
    changeWorkspaceIdDocsLink: 'https://confluence.atlassian.com/x/hneeOQ',
    contactSupportLink:
      'https://support.atlassian.com/contact/#/?inquiry_category=technical_issues',
    troubleshootWorkspaceInvites:
      'https://support.atlassian.com/bitbucket-cloud/docs/grant-access-to-a-workspace/#Troubleshooting-member-invitations',
    deprecationNoticeLink:
      'https://community.atlassian.com/t5/Bitbucket-articles/Reminder-Deprecation-of-Legacy-Permissions/ba-p/2483614',
  },
  xhr: {
    addMemberToGroup: (workspaceSlug: string) =>
      `/${workspaceSlug}/workspace/settings/groups/xhr/add-member/`,
  },
};

export default urls;
