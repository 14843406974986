/* eslint-disable no-underscore-dangle */
import React from 'react';

import PropTypes from 'prop-types';

import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

/**
 * This is a copy of react-loadable/index.js.
 *
 * To get hydration working with Parcel we need to override the `isWebpackReady`
 * function that react-loadable uses, which checks for some global properties webpack provides.
 *
 * This file is only used by Parcel builds.
 */

const ALL_INITIALIZERS: (() => any)[] = [];
const READY_INITIALIZERS: (() => any)[] = [];

declare global {
  let __preload_ready__: { [key: string]: true };
}

function modulesReady(moduleIds: string[]) {
  if (typeof __preload_ready__ !== 'object') {
    return false;
  }
  return moduleIds.every(moduleId => {
    return (
      typeof moduleId !== 'undefined' &&
      typeof __preload_ready__[moduleId] !== 'undefined'
    );
  });
}

function load(loader: () => any) {
  const promise = loader();

  const state: {
    loading: boolean;
    loaded: any;
    error: any;
    promise?: any;
  } = {
    loading: true,
    loaded: null,
    error: null,
  };

  state.promise = promise
    .then((loaded: any) => {
      state.loading = false;
      state.loaded = loaded;
      return loaded;
    })
    .catch((err: null) => {
      state.loading = false;
      state.error = err;
      throw err;
    });

  return state;
}

function loadMap(obj: { [x: string]: () => any }) {
  const state: {
    loading: boolean;
    loaded: any;
    error: any;
    promise?: any;
  } = {
    loading: false,
    loaded: {},
    error: null,
  };

  const promises: any[] = [];

  try {
    Object.keys(obj).forEach(key => {
      const result: any = load(obj[key]);

      if (!result.loading) {
        state.loaded[key] = result.loaded;
        state.error = result.error;
      } else {
        state.loading = true;
      }

      promises.push(result.promise);

      result.promise
        .then((res: any) => {
          state.loaded[key] = res;
        })
        .catch((err: null) => {
          state.error = err;
        });
    });
  } catch (err) {
    state.error = err;
  }

  state.promise = Promise.all(promises)
    .then(res => {
      state.loading = false;
      return res;
    })
    .catch(err => {
      state.loading = false;
      throw err;
    });

  return state;
}

function resolveModule(obj: { __esModule: any; default: any }) {
  // eslint-disable-next-line no-underscore-dangle
  return obj && obj.__esModule ? obj.default : obj;
}

function render(loaded: any, props: any) {
  return React.createElement(resolveModule(loaded), props);
}

function createLoadableComponent(loadFn: any, options: any) {
  if (!options.loading) {
    throw new Error('react-loadable requires a `loading` component');
  }

  const opts = Object.assign(
    {
      loader: null,
      loading: null,
      delay: 200,
      timeout: null,
      render,
      webpack: null,
      modules: null,
    },
    options
  );

  let res: any = null;

  function init() {
    if (!res) {
      res = loadFn(opts.loader);
    }
    return res.promise;
  }

  ALL_INITIALIZERS.push(init);

  if (Array.isArray(opts.modules)) {
    // eslint-disable-next-line consistent-return
    READY_INITIALIZERS.push(() => {
      /**
       * SSR specific override.
       *
       * We always want to `init` for server side rendered
       * pages as the bundles will have been prefetched.
       *
       * If the page has not been SSRed, then we fallback to just loading the module manually.
       */
      if (modulesReady(opts.modules)) {
        return init();
      }
    });
  }

  // TODO: Replace usage of unsafe lifecycle methods
  // eslint-disable-next-line react/no-unsafe
  return class LoadableComponent extends React.Component<any, any> {
    _mounted: boolean;
    _delay: NodeJS.Timeout;
    _timeout: NodeJS.Timeout;

    /* eslint react/sort-comp: warn */
    static contextTypes = {
      loadable: PropTypes.shape({
        report: PropTypes.func.isRequired,
      }),
    };

    static preload() {
      return init();
    }

    constructor(props: any) {
      super(props);
      init();

      this.state = {
        error: res.error,
        pastDelay: false,
        timedOut: false,
        loading: res.loading,
        loaded: res.loaded,
      };
    }

    /* eslint react/no-unsafe: warn */
    UNSAFE_componentWillMount() {
      this._mounted = true;
      this._loadModule();
    }

    componentWillUnmount() {
      this._mounted = false;
      this._clearTimeouts();
    }

    _loadModule() {
      if (this.context.loadable && Array.isArray(opts.modules)) {
        // @ts-ignore
        opts.modules.forEach((moduleName: any) => {
          this.context.loadable.report(moduleName);
        });
      }

      if (!res.loading) {
        return;
      }

      if (typeof opts.delay === 'number') {
        if (opts.delay === 0) {
          this.setState({ pastDelay: true });
        } else {
          this._delay = setTimeout(() => {
            this.setState({ pastDelay: true });
          }, opts.delay);
        }
      }

      if (typeof opts.timeout === 'number') {
        this._timeout = setTimeout(() => {
          this.setState({ timedOut: true });
          // @ts-ignore
        }, opts.timeout);
      }

      const update = () => {
        if (!this._mounted) {
          return;
        }

        this.setState({
          error: res.error,
          loaded: res.loaded,
          loading: res.loading,
        });

        this._clearTimeouts();
      };

      res.promise
        .then(() => {
          update();
        })
        .catch((_err: any) => {
          update();
        });
    }

    _clearTimeouts() {
      clearTimeout(this._delay);
      clearTimeout(this._timeout);
    }

    retry = () => {
      this.setState({ error: null, loading: true, timedOut: false });
      res = loadFn(opts.loader);
      this._loadModule();
    };

    render() {
      if (this.state.loading || this.state.error) {
        return React.createElement(
          UFOLoadHold, // register Loadable loading state as a UFO Hold
          { name: `loadable-hold-${opts.modules}` },
          React.createElement(opts.loading, {
            isLoading: this.state.loading,
            pastDelay: this.state.pastDelay,
            timedOut: this.state.timedOut,
            error: this.state.error,
            retry: this.retry,
          })
        );
      } else if (this.state.loaded) {
        return opts.render(this.state.loaded, this.props);
      } else {
        return null;
      }
    }
  };
}

// react-loadable, unlike React.lazy, checks for an `__esModule` property on
// the exports object when determining whether to use the default export,
// falling back to using the entire exports object. Always prefer the default
// export if it's available, falling back to the entire exports object for
// compatibility.
function Loadable(opts: any) {
  return createLoadableComponent(load, {
    ...opts,
    render:
      opts.render != null
        ? opts.render
        : (loaded: any, props: any) =>
            React.createElement(
              // Always prefer a default property from an ESM, falling back for
              // compatibility.
              Object.prototype.hasOwnProperty.call(loaded, 'default')
                ? loaded.default
                : loaded,
              props
            ),
  });
}

function LoadableMap(opts: any) {
  if (typeof opts.render !== 'function') {
    throw new Error('LoadableMap requires a `render(loaded, props)` function');
  }

  return createLoadableComponent(loadMap, opts);
}

Loadable.Map = LoadableMap;

class Capture extends React.Component<any, any> {
  static propTypes = {
    report: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    loadable: PropTypes.shape({
      report: PropTypes.func.isRequired,
    }).isRequired,
  };

  getChildContext() {
    return {
      loadable: {
        report: this.props.report,
      },
    };
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

Loadable.Capture = Capture;

function flushInitializers(initializers: any[]): Promise<any> {
  const promises: Promise<any>[] = [];

  while (initializers.length) {
    const init = initializers.pop();
    promises.push(init());
  }

  // eslint-disable-next-line consistent-return
  return Promise.all(promises).then(() => {
    if (initializers.length) {
      return flushInitializers(initializers);
    }
  });
}

Loadable.preloadAll = () => {
  return new Promise((resolve, reject) => {
    flushInitializers(ALL_INITIALIZERS).then(resolve, reject);
  });
};

Loadable.preloadReady = () => {
  return new Promise((resolve, _reject) => {
    // We always will resolve, errors should be handled within loading UIs.
    flushInitializers(READY_INITIALIZERS).then(resolve, resolve);
  });
};

module.exports = Loadable;
