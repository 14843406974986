import { createResource, RouteResource } from 'react-resource-router';

import { useThirdPartySecretsProvider } from 'src/components/pipelines/hooks/use-third-party-secrets-endpoints';
import { FAILED_LOAD } from 'src/components/pipelines/pages/admin/variables/SecretProviderConfiguration';
import { getThirdPartySecretsProviderUrl } from 'src/components/pipelines/urls';
import { capturePipelinesExceptionWithTags } from 'src/components/pipelines/utils/sentry';
import authRequest from 'src/utils/fetch';

async function fetchData(url: string) {
  try {
    const req = authRequest(url);
    const resp = await fetch(req);
    if (!resp.ok) {
      if (resp.status === 404) {
        return { appAri: '' };
      }
    }
    const body = await resp.json();
    return { appAri: body.url || '' };
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: 'pipelines_fetch_third_party_secrets_provider_exception',
    });
    return { appAri: FAILED_LOAD };
  }
}

type ThirdPartySecretsResourceOptions = {
  getRequestUrl: (params: {}) => string;
  type: string;
};

export type ThirdPartySecretsResource = {
  appAri: string;
};

function fetchThirdPartySecretsUrl({
  getRequestUrl,
  type,
}: ThirdPartySecretsResourceOptions) {
  return createResource<ThirdPartySecretsResource>({
    type,
    getKey: ({ match }) => getRequestUrl(match.params),
    maxAge: 60000,
    getData: async ({ match }) => {
      const initialData = await fetchData(getRequestUrl(match.params));
      return { ...initialData };
    },
  });
}

export const loadThirdPartySecretsProviderResource = fetchThirdPartySecretsUrl({
  getRequestUrl: getThirdPartySecretsProviderUrl,
  type: 'pipelinesWorkspaceThirdPartySecrets',
});

export type ThirdPartySecretsProvider = {
  selectedAppAri: string;
  setThirdPartySecretsProvider: (selectedAppAri: string) => Promise<boolean>;
  deleteThirdPartySecretsProvider: () => Promise<boolean>;
  isThirdPartySecretsProviderResourceLoading: boolean;
};

export type UseThirdPartySecretsProvider = {
  resource: RouteResource<ThirdPartySecretsResource>;
  getRequestUrl: (params: {}) => string;
};

export type Params = {
  workspaceSlug?: string;
  repositoryOwner?: string;
  repositorySlug?: string;
};

export function useWorkspaceThirdPartySecretsProvider(): ThirdPartySecretsProvider {
  return useThirdPartySecretsProvider({
    resource: loadThirdPartySecretsProviderResource,
    getRequestUrl: getThirdPartySecretsProviderUrl,
  });
}
