import qs from 'qs';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, select, delay } from 'redux-saga/effects';

import { showFlagComponent } from 'src/redux/flags';
import urls from 'src/sections/repository/urls';
import authRequest from 'src/utils/fetch';

import {
  COMPARE_BRANCHES,
  COMPARE_BRANCHES_POLL_STATUS,
  COMPARE_BRANCHES_POLL_STATUS_TIMEOUT,
  compareBranchesPollStatus,
  CompareBranchesAction,
  CompareBranchesPollStatusAction,
  closeCompareBranchesDialog,
} from '../actions';
import {
  CompareBranchesPollStatusSuccessAction,
  CompareBranchesStatusErrorAction,
  PollStatus,
} from '../actions/compare-branches';
import { CompareBranchesDialogState } from '../reducers/compare-branches-reducer';
import { getCompareBranchesDialogSlice } from '../selectors';

export default function* compareBranchesSaga(action: CompareBranchesAction) {
  const {
    isMerge,
    sourceBranchName,
    destinationBranchName,
    sourceRepositoryFullName,
    destinationRepositoryFullName,
  }: CompareBranchesDialogState = yield select(getCompareBranchesDialogSlice);

  const { commitMessage, closeBranch, strategy, currentPullRequestId } =
    action.payload;

  let body: any = {
    commit_message: commitMessage,
    close_branch: closeBranch,
    source: `${sourceRepositoryFullName}::${sourceBranchName}`,
    dest: `${destinationRepositoryFullName}::${destinationBranchName}`,
    event: isMerge && strategy ? 'list:merge' : 'list:sync',
  };

  if (isMerge && strategy) {
    body = { ...body, merge_strategy: strategy };
  } else {
    body = {
      ...body,
      sync_strategy: strategy,
      local_pullrequest_id: currentPullRequestId,
    };
  }

  const url = urls.api.internal.compare(
    destinationRepositoryFullName as string
  );
  const request = {
    method: 'POST',
    body: qs.stringify(body, { skipNulls: true }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };

  try {
    const res: Response = yield call(fetch, authRequest(url, request));
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    // @ts-ignore
    const data = yield call([res, 'json']);
    if (data?.error && data.error.length > 0) {
      yield put({
        type: COMPARE_BRANCHES.ERROR,
        payload: { errorMessage: data.error[0] },
      });
    } else {
      yield put({
        type: COMPARE_BRANCHES.SUCCESS,
        payload: data,
      });
    }
  } catch (e) {
    yield put({
      type: COMPARE_BRANCHES.ERROR,
      payload: e,
    });
  }
}

export const MAX_POLL_STATUS_CHECKS = 60;

export function* compareBranchesPollStatusSaga(
  action: CompareBranchesPollStatusAction
) {
  try {
    let status: PollStatus = {};
    let i = 0;
    while (!status.complete && i < MAX_POLL_STATUS_CHECKS) {
      const res: Response = yield call(fetch, authRequest(action.payload.url));
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      status = yield call([res, 'json']);
      i++;
      if (!status.complete) {
        const delayTimeout = Math.min(Math.max(250 * i, 1000), 5000);
        yield delay(delayTimeout);
      }
    }
    if (status.error) {
      yield put({
        type: COMPARE_BRANCHES_POLL_STATUS.ERROR,
        payload: status.error,
      });
      return;
    }
    if (!status.complete) {
      yield put({
        type: COMPARE_BRANCHES_POLL_STATUS_TIMEOUT,
      });
      return;
    }
    yield put({
      type: COMPARE_BRANCHES_POLL_STATUS.SUCCESS,
      payload: status,
    });
  } catch (e) {
    yield put({
      type: COMPARE_BRANCHES_POLL_STATUS.ERROR,
    });
  }
}

export function* handleCompareSuccessSaga(
  action: CompareBranchesPollStatusAction
) {
  yield put(compareBranchesPollStatus(action.payload));
}

export function* handlePollSuccessSaga(
  action: CompareBranchesPollStatusSuccessAction
) {
  yield put(closeCompareBranchesDialog());
  const { reloadAction, renderFlagOnSuccess } = yield select(
    getCompareBranchesDialogSlice
  );

  if (reloadAction) {
    yield put(reloadAction);
  }

  if (renderFlagOnSuccess) {
    yield put(showFlagComponent('compare-branches-success'));
  } else if (action?.payload?.url) {
    location.assign(action.payload.url);
  }
}

export function* handlePollTimeoutSaga() {
  yield put(closeCompareBranchesDialog());
  yield put(showFlagComponent('compare-branches-timeout'));
}

export function* handleCompareErrorSaga(
  action?: CompareBranchesStatusErrorAction
) {
  yield put(closeCompareBranchesDialog(action?.payload));
  yield put(showFlagComponent('compare-branches-error'));
}
