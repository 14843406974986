import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import * as componentTokens from './component-tokens';
var disabledRules = {
  backgroundColor: componentTokens.disabledBackground,
  backgroundColorFocus: componentTokens.disabledBackground,
  backgroundColorHover: componentTokens.disabledBackground,
  borderColor: componentTokens.disabledBorder,
  borderColorFocus: componentTokens.defaultBorderColorFocus,
  textColor: componentTokens.disabledTextColor
};
var invalidRules = {
  borderColor: componentTokens.invalidBorderColor,
  borderColorFocus: componentTokens.defaultBorderColorFocus,
  backgroundColor: componentTokens.defaultBackgroundColor,
  backgroundColorFocus: componentTokens.defaultBackgroundColorFocus,
  backgroundColorHover: componentTokens.defaultBackgroundColorHover
};
var backgroundColor = {
  standard: componentTokens.defaultBackgroundColor,
  subtle: componentTokens.transparent,
  none: componentTokens.transparent
};
var backgroundColorFocus = {
  standard: componentTokens.defaultBackgroundColorFocus,
  subtle: componentTokens.defaultBackgroundColorFocus,
  none: componentTokens.transparent
};
var backgroundColorHover = {
  standard: componentTokens.defaultBackgroundColorHover,
  subtle: componentTokens.defaultBackgroundColorHover,
  none: componentTokens.transparent
};
var borderColor = {
  standard: componentTokens.defaultBorderColor,
  subtle: componentTokens.transparent,
  none: componentTokens.transparent
};
var borderColorFocus = {
  standard: componentTokens.defaultBorderColorFocus,
  subtle: componentTokens.defaultBorderColorFocus,
  none: componentTokens.transparent
};
var borderColorHover = {
  standard: componentTokens.defaultBorderColor,
  subtle: componentTokens.subtleBorderColorHover,
  none: componentTokens.transparent
};
var lineHeightBase = 8 * 2.5;
var lineHeightCompact = 8 * 2;
var compactVerticalPadding = 2;
var verticalPadding = 6;
var horizontalPadding = 8;
var transitionDuration = '0.2s';
var fontFamily = "var(--ds-font-family-body, ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)";
var codeFontFamily = "var(--ds-font-family-code, ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace)";
export var borderWidth = 2;

// Safari puts on some difficult to remove styles, mainly for disabled inputs
// but we want full control so need to override them in all cases
var overrideSafariDisabledStyles = {
  WebkitTextFillColor: 'unset',
  WebkitOpacity: '1'
};
var borderBoxMinHeight = function borderBoxMinHeight(minimumRows, borderHeight) {
  var contentHeight = lineHeightBase * minimumRows;
  return contentHeight + verticalPadding * 2 + borderHeight * 2;
};
var borderBoxMinHeightCompact = function borderBoxMinHeightCompact(minimumRows, borderHeight) {
  var contentHeightCompact = lineHeightCompact * minimumRows;
  return contentHeightCompact + compactVerticalPadding * 2 + borderHeight * 2;
};
var bgAndBorderColorStyles = function bgAndBorderColorStyles(appearance) {
  return appearance && css(_objectSpread({
    '&:focus': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: backgroundColorFocus[appearance],
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderColor: borderColorFocus[appearance],
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColorFocus[appearance])
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
    '&:not(:focus)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: backgroundColor[appearance],
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderColor: borderColor[appearance]
    },
    // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '&[data-invalid]:focus': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: invalidRules.backgroundColorFocus,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderColor: invalidRules.borderColorFocus,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", invalidRules.borderColorFocus)
    },
    // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
    '&[data-invalid]:not(:focus)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: invalidRules.backgroundColor,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderColor: invalidRules.borderColor,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", invalidRules.borderColor)
    }
  }, appearance === 'standard' ? {
    '&:disabled:focus': {
      backgroundColor: disabledRules.backgroundColorFocus,
      borderColor: disabledRules.borderColorFocus
    },
    '&:disabled:not(:focus)': {
      backgroundColor: disabledRules.backgroundColor,
      borderColor: disabledRules.borderColor
    }
  } : {}));
};
var placeholderStyles = css({
  '&::placeholder': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: componentTokens.placeholderTextColor
  },
  '&:disabled::placeholder': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: disabledRules.textColor
  }
});
var hoverBackgroundAndBorderStyles = function hoverBackgroundAndBorderStyles(appearance) {
  return appearance && css({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
    '&:hover:not(:read-only):not(:focus)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: backgroundColorHover[appearance],
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderColor: borderColorHover[appearance],
      '&:disabled': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        backgroundColor: disabledRules.backgroundColorHover
      },
      // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
      '&[data-invalid]': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        backgroundColor: invalidRules.backgroundColorHover,
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        borderColor: invalidRules.borderColor,
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", invalidRules.borderColor)
      }
    }
  });
};
var resizeStyle = function resizeStyle(resize) {
  if (resize === 'horizontal' || resize === 'vertical') {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    return css({
      resize: resize
    });
  }
  if (resize === 'auto') {
    return css({
      resize: 'both'
    });
  }
  return css({
    resize: 'none'
  });
};
var borderStyle = function borderStyle(appearance) {
  return css({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderStyle: appearance === 'none' ? 'none' : 'solid'
  });
};
var fontFamilyStyle = function fontFamilyStyle(isMonospaced) {
  return css({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    fontFamily: isMonospaced ? codeFontFamily : fontFamily
  });
};
var borderPaddingAndHeightStyles = function borderPaddingAndHeightStyles() {
  var minimumRows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var appearance = arguments.length > 1 ? arguments[1] : undefined;
  var borderWidth = appearance !== 'none' ? 1 : 2;
  var horizontalPaddingWithoutBorderWidth = horizontalPadding - borderWidth;
  var borderHeight = borderWidth;
  return css({
    // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '&[data-compact]': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      minHeight: borderBoxMinHeightCompact(minimumRows, borderHeight),
      // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      padding: "".concat(compactVerticalPadding, "px ").concat(horizontalPaddingWithoutBorderWidth, "px"),
      // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values
      lineHeight: lineHeightCompact / 14
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
    '&:not([data-compact])': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      minHeight: borderBoxMinHeight(minimumRows, borderHeight),
      // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      padding: "".concat(verticalPadding, "px ").concat(horizontalPaddingWithoutBorderWidth, "px"),
      // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values
      lineHeight: lineHeightBase / 14
    }
  });
};
var staticStyles = css({
  display: 'block',
  boxSizing: 'border-box',
  width: '100%',
  minWidth: 0,
  maxWidth: '100%',
  margin: 0,
  position: 'relative',
  flex: '1 1 100%',
  borderRadius: "var(--ds-border-radius, 3px)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  borderWidth: 1,
  font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  outline: 'none',
  overflow: 'auto',
  transition: "background-color ".concat(transitionDuration, " ease-in-out,\n               border-color ").concat(transitionDuration, " ease-in-out"),
  wordWrap: 'break-word',
  '&:disabled': _objectSpread({
    cursor: 'not-allowed'
  }, overrideSafariDisabledStyles),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&::-ms-clear': {
    display: 'none'
  },
  '&:invalid': {
    boxShadow: 'none'
  }
});
export var getBaseStyles = function getBaseStyles(_ref) {
  var minimumRows = _ref.minimumRows,
    resize = _ref.resize,
    appearance = _ref.appearance,
    isMonospaced = _ref.isMonospaced,
    maxHeight = _ref.maxHeight;
  return (
    // eslint-disable-next-line @repo/internal/styles/no-exported-styles
    css(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    staticStyles,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderPaddingAndHeightStyles(minimumRows, appearance),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    resizeStyle(resize),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderStyle(appearance),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    fontFamilyStyle(isMonospaced),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    {
      maxHeight: maxHeight
    })
  );
};
export var dynamicStyles = function dynamicStyles(appearance) {
  return (
    // eslint-disable-next-line @repo/internal/styles/no-exported-styles
    css(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    bgAndBorderColorStyles(appearance),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    hoverBackgroundAndBorderStyles(appearance),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    placeholderStyles, {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      color: componentTokens.textColor,
      '&:disabled': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        color: disabledRules.textColor
      }
    })
  );
};