import { Config } from '@atlaskit/react-ufo/config';

import { PERFORMANCE_MARK_SSR_HTML } from '../../ssr/services/metrics/constants';

import { getMark } from './utils/getMark';

const FIRST_PAINT_MARK = 'first-paint';

const ssrTimings = () => {
  const ssrTime = getMark(PERFORMANCE_MARK_SSR_HTML);
  if (!ssrTime) {
    return [];
  }
  return [{ label: 'ssr', data: { startTime: 0, duration: ssrTime } }];
};

export const devConfig: Config = {
  enabled: true,
  doNotAbortActivePressInteraction: [],
  doNotAbortActivePressInteractionOnTransition: [],
  awaitBM3TTI: [],
  ufoNameOverrides: {},
  autoGeneratedRate: 1000,
  namePrefix: 'ufo-',
  segmentPrefix: 'ufo-',
  rates: {},
  rules: [],
  killswitch: [],
  typingMethod: 'timeout',
  capability: {
    feature_flag_access: 0,
    react_profiler: 1,
  },
  kind: {
    page_load: 1,
    transition: 1,
    hover: 0,
    legacy: 0,
    press: 1,
    typing: 0,
  },
  // disable collection of resource timings for 'fetch' requests, as they
  // currently contain UGC, e.g. workspace/repository/branch names
  // TODO: remove `allowedResource` config once `sanitiseEndpoints` function is provided
  allowedResources: ['script', 'link'],
  removePageSegmentsUFOPrefixes: false,
  removeInteractionsUFOPrefixes: true,
  product: 'bitbucket',
  region: 'unknown',
  ssr: {
    getSSRTimings: () => ssrTimings(),
    getSSRDoneTime: () => getMark(FIRST_PAINT_MARK),
  },
  vc: {
    enabled: true,
    heatmapSize: 200,
    oldDomUpdates: false,
    devToolsEnabled: true,
    selectorConfig: {
      // This must be false because our README markup contains UGC in some ids
      // and we cannot log that data.
      id: false,
      role: true,
      className: true,
      testId: true,
    },
  },
  enableCompleteUsingDoubleRAF: true,
  postInteractionLog: {
    enabled: true,
    rates: {
      'repository-branch-detail': 1,
      'repository-branches-list': 1,
      'repository-commit': 1,
      'repository-commits': 1,
      'workspace-projects': 1,
      'workspace-pull-requests': 1,
      'workspace-repositories': 1,
      'repository-code-review-overview': 1,
      'repository-code-review-diff': 1,
      'repository-code-review-commits': 1,
    },
  },
};

export const prodConfig: Config = {
  ...devConfig,
  rates: {
    view_page: 1000,
  },
};
