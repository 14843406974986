import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pullRequestMergedFlagMessage: {
    id: 'frontbucket.repository.pullrequest.flags.pullRequestMerged',
    description:
      'Text for title of flag indicating pull request has been merged',
    defaultMessage: 'This pull request has been merged',
  },
});
