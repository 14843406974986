import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import { withFocusWithin } from 'react-focus-within';
// TODO: should use @emotion
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';

// AFP-2532 TODO: Fix automatic suppressions below
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- needs manual remediation
var transition = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\ttransition: all 200ms ease-in-out;\n"])));

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var PanelWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tmargin: 0 auto ", ";\n"])), "var(--ds-space-200, 16px)");

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tleft: 0;\n\tline-height: 0;\n\topacity: ", ";\n\tposition: absolute;\n\t", ";\n\n\t/* IE 11 needs these vertical positioning rules - the flexbox\n  behavior for absolute-positioned children is not up to spec.\n  https://googlechrome.github.io/samples/css-flexbox-abspos/ */\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\n\tbutton {\n\t\tpointer-events: none;\n\t}\n"])), function (_ref) {
  var isHidden = _ref.isHidden;
  return isHidden ? 0 : 1;
}, transition);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var PanelHeader = withFocusWithin(styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\talign-items: center;\n\tbackground-color: ", ";\n\tborder-radius: ", ";\n\tdisplay: flex;\n\tleft: ", ";\n\tmargin-bottom: ", ";\n\tmargin-top: ", ";\n\tpadding: ", " ", " ", "\n\t\t", ";\n\tposition: relative;\n\t", ";\n\twidth: 100%;\n\n\t", " {\n\t\topacity: ", ";\n\t}\n\n\t&:hover {\n\t\tbackground-color: ", ";\n\t\tcursor: pointer;\n\n\t\t", " {\n\t\t\topacity: 1;\n\t\t}\n\t}\n"])), function (props) {
  return props.isFocused && "var(--ds-surface-hovered, ".concat(colors.N20, ")");
}, "var(--ds-border-radius-100, 3px)", "var(--ds-space-negative-300, -24px)", "var(--ds-space-100, 8px)", "var(--ds-space-200, 16px)", "var(--ds-space-025, 2px)", "var(--ds-space-0, 0px)", "var(--ds-space-025, 2px)", "var(--ds-space-300, 24px)", transition, ButtonWrapper, function (props) {
  return props.isFocused && 1;
}, "var(--ds-surface-hovered, ".concat(colors.N20, ")"), ButtonWrapper));