import durationToSeconds from '../utils/durationToSeconds';
import { flatten } from '../utils/flatten';
import { pick } from '../utils/pick';

const testStatusMap: { [key in TestReportStatus]: string } = {
  SUCCESSFUL: 'PASSED',
  FAILED: 'FAILED',
  SKIPPED: 'SKIPPED',
  ERROR: 'ERROR',
};

export class TestReportResult {
  readonly step_uuid: string = '';
  readonly number_of_error_test_cases: number = 0;
  readonly number_of_failed_test_cases: number = 0;
  readonly number_of_skipped_test_cases: number = 0;
  readonly number_of_successful_test_cases: number = 0;
  readonly number_of_test_cases: number = 0;

  constructor(props: Partial<TestReportResult> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class TestCaseReason {
  readonly uuid: string = '';
  readonly message: string = '';
  readonly stack_trace: string = '';

  constructor(props: Partial<TestCaseReason> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }

  get logLines(): string[] {
    const logLines: any[] = [];
    if (this.message) {
      logLines.push(...this.message.split('\n'));
      // add extra empty line between message and stack trace
      logLines.push('');
    }

    if (this.stack_trace) {
      logLines.push(...this.stack_trace.split('\n'));
      // add extra empty line for proper rendering of truncated logs
      logLines.push('');
    }
    return logLines;
  }
}

export class TestCaseReasonError {
  readonly error: any = undefined;
  readonly 'error.key': string = '';
  readonly 'error.message': number = 0;

  constructor(props: Partial<TestCaseReasonError> = {}) {
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
    });
    Object.freeze(this);
  }
}

export type TestCaseResponse = {
  uuid: string;
  name: string;
  status: string;
  duration: string;
  class_name: string;
  package_name: string;
  suite_name: string;
  fully_qualified_name: string;
  custom_properties: Array<{ key: string; value: string }>;
  metrics: {
    p90_duration: number;
    p10_duration: number;
    average_duration: number;
    failure_rate: number;
  };
};

export class TestCase {
  readonly uuid: string = '';
  readonly name: string = '';
  readonly status: string = '';
  readonly duration: string = '';
  readonly className: string = '';
  readonly packageName: string = '';
  readonly suiteName: string = '';
  readonly fullyQualifiedName: string = '';
  readonly customProperties: Array<{ key: string; value: string }> = [];
  readonly metrics: {
    p90Duration: number;
    p10Duration: number;
    averageDuration: number;
    failureRate: number;
  } = {
    p90Duration: 0,
    p10Duration: 0,
    averageDuration: 0,
    failureRate: 0,
  };

  constructor(props: Partial<TestCaseResponse> = {}) {
    Object.assign(this, {
      uuid: props.uuid,
      name: props.name,
      status: props.status,
      duration: props.duration ? durationToSeconds(props.duration) : null,
      className: props.class_name,
      packageName: props.package_name,
      suiteName: props.suite_name,
      fullyQualifiedName: props.fully_qualified_name,
      customProperties: props.custom_properties,
      metrics: {
        p90Duration: props.metrics?.p90_duration,
        p10Duration: props.metrics?.p10_duration,
        averageDuration: props.metrics?.average_duration,
        failureRate: props.metrics?.failure_rate,
      },
    });
    Object.freeze(this);
  }
}

export type TestExecutionResponse = {
  uuid: string;
  name: string;
  status: TestReportStatus;
  duration: string;
  test_report_uuid: string;
  test_summary_uuid: string;
  fully_qualified_name: string;
  class_name: string;
  suite_name: string;
  package_name: string;
  created_on: string;
  target: {
    reference_name: string;
    revision: string;
    type: string;
  };
};

export class TestExecution {
  readonly uuid: string = '';
  readonly name: string = '';
  readonly status: string = '';
  readonly duration: string = '';
  readonly testReportUuid: string = '';
  readonly testSummaryUuid: string = '';
  readonly fullyQualifiedName: string = '';
  readonly className: string = '';
  readonly suiteName: string = '';
  readonly packageName: string = '';
  readonly createdOn: string = '';
  readonly target: {
    referenceName: string;
    revision: string;
    type: string;
  } = {
    referenceName: '',
    revision: '',
    type: '',
  };

  constructor(props: Partial<TestExecutionResponse> = {}) {
    Object.assign(this, {
      uuid: props.uuid,
      name: props.name,
      testReportUuid: props.test_report_uuid,
      testSummaryUuid: props.test_summary_uuid,
      fullyQualifiedName: props.fully_qualified_name,
      className: props.class_name,
      suiteName: props.suite_name,
      packageName: props.package_name,
      createdOn: props.created_on,
      status: testStatusMap[props.status as TestReportStatus],
      target: {
        referenceName: props.target?.reference_name,
        revision: props.target?.revision,
        type: props.target?.type,
      },
      duration: props.duration ? props.duration : null,
    });

    Object.freeze(this);
  }
}

export type TestReportStatus = 'SUCCESSFUL' | 'FAILED' | 'ERROR' | 'SKIPPED';
export type TestCaseReasonMap = {
  [key: string]: TestCaseReason | TestCaseReasonError;
};
