import { gridSize, layers } from '@atlaskit/theme';

import { BANNER_HEIGHT } from 'src/constants/navigation';

const MODAL_MENU_PORTAL_LAYER = layers.modal() + 10;

export const getModalMenuPortalStyles = (
  isBannerOpen: boolean,
  minWidth?: string
) => {
  return {
    // @ts-ignore TODO: Upgrade @atlaskit/select and use `StylesConfig` as this full object's type
    // to fix noImplicitAny error here
    menuPortal: base => {
      const topOverride = isBannerOpen ? { top: base.top - BANNER_HEIGHT } : {};

      return {
        ...base,
        ...topOverride,
        zIndex: MODAL_MENU_PORTAL_LAYER,
        minWidth,
      };
    },
  };
};

// Extract the common selectControlStyles for SelectComponents on the Branches and Pull requests pages.
export const selectControlStyles = {
  minHeight: gridSize() * 4,
  height: gridSize() * 4,
  alignContent: 'center',
};
