/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { N300 } from '@atlaskit/theme/colors';
import { headingSizes } from '@atlaskit/theme/typography';
var itemHeadingContentHeight = headingSizes.h100.lineHeight;
var itemHeadingFontSize = headingSizes.h100.size;
var headingStyles = css({
  color: "var(--ds-text-subtle, ".concat(N300, ")"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  fontSize: itemHeadingFontSize,
  fontWeight: "var(--ds-font-weight-bold, 700)",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  lineHeight: itemHeadingContentHeight / itemHeadingFontSize,
  paddingBlock: "var(--ds-space-0, 0px)",
  paddingInline: "var(--ds-space-200, 16px)",
  textTransform: 'uppercase'
});

/**
 * __Group title__
 *
 * Used to visually represent the title for DropdownMenu groups
 *
 * @internal
 */
var GroupTitle = function GroupTitle(_ref) {
  var id = _ref.id,
    title = _ref.title;
  return jsx("div", {
    "data-ds--menu--heading-item": true,
    role: "menuitem",
    id: id,
    "aria-hidden": "true",
    css: headingStyles
  }, title);
};
export default GroupTitle;