import { useRef } from 'react';
export var PREFIX = 'ds--dropdown--';
var generateRandomString = function generateRandomString() {
  return (// This string is used only on client side usually triggered after a user interaction.
    // Therefore, so there is no risk of mismatch
    // between server and client generated markup.
    // eslint-disable-next-line @repo/internal/react/disallow-unstable-values
    "".concat(PREFIX).concat(Math.random().toString(16).substr(2, 8))
  );
};

/**
 * useGeneratedId generates a random string which remains constant across
 * renders when called without any parameter.
 */
export default function useGeneratedId() {
  var cachedId = useRef(generateRandomString());
  return cachedId.current;
}