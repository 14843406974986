import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  fieldLabel: {
    id: 'frontbucket.workspace.settings.secrets.field',
    description: 'secret provider field name',
    defaultMessage: 'Secret provider endpoint',
  },
  add: {
    id: 'frontbucket.workspace.settings.secrets.buttons.add',
    description: 'Save action button name',
    defaultMessage: 'Add',
  },
  remove: {
    id: 'frontbucket.workspace.settings.secrets.buttons.remove',
    description: 'Remove action button name',
    defaultMessage: 'Remove',
  },
  invalidUrlMessage: {
    id: 'frontbucket.workspace.settings.secrets.errorMessage',
    description: 'Error message for invalid URL',
    defaultMessage: 'The URL you entered seems to be incorrect or incomplete',
  },
  setFailedErrorFlagMsg: {
    id: 'frontbucket.workspace.settings.secrets.set.errorFlagMsg',
    description: 'Error flag message for failing to set secret provider url',
    defaultMessage:
      'Failed to update the third party secrets provider endpoint.',
  },
  deleteFailedErrorFlagMsg: {
    id: 'frontbucket.workspace.settings.secrets.delete.errorFlagMsg',
    description: 'Error flag message for failing to delete secret provider url',
    defaultMessage:
      'Failed to delete the third party secrets provider endpoint.',
  },
  failedFetchErrorFlagMsg: {
    id: 'frontbucket.workspace.settings.secrets.fetch.errorFlagMsg',
    description: 'Error flag message for failing to fetch secret provider url',
    defaultMessage:
      'Failed to fetch the third party secrets provider endpoint.',
  },
  successfullySetFlagMsg: {
    id: 'frontbucket.workspace.settings.secrets.set.successFlagMsg',
    description:
      'success flag message for failing to update secret provider url',
    defaultMessage: 'Third party secrets url has been successfully added',
  },
  successfullyDeletedFlagMsg: {
    id: 'frontbucket.workspace.settings.secrets.delete.successFlagMsg',
    description: 'success flag message for deleting secret provider url',
    defaultMessage: 'Third party secrets url has been successfully deleted',
  },
});
